import React, { FC, useEffect, useState } from "react";
import s from "./LongPaywallSummary.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useContainerData } from "../../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../../../checkout.store";
import { LongPaywallProduct, LongPaywallProductNames, PaymentMethods, PaywallClickTypes } from "../../../../../paywalls.constants";
import { Timeline, TimelineMilestone } from "../../Timeline/Timeline";
import { TimelineMilestone1, TimelineMilestone2, TimelineMilestone3 } from "../../../../ui/icons";
import { Container, i18n } from "../../../../../../../symphony";
import { ApplePay } from "../../../../checkout/ApplePay/ApplePay";
import { PaypalPayment } from "../../../../checkout/PaypalPayment/PaypalPayment";
import { PaywallPageButton } from "../../../../PaywallPageButton/PaywallPageButton";
import { PaywallButtonAction, PaywallButtonType } from "../../../../../../../api/public/subscription-api";
import { PaywallsService } from "../../../../../services/paywalls.service";

type Props = {};

export const LongPaywallSummary: FC<Props> = () => {
	useStyles(s);
	const { trackPaywallClick } = Container.take('paywalls', PaywallsService);
	const [currentProduct, setCurrentProduct] = useState<LongPaywallProduct>(null);
	const [hasTrial, setHasTrial] = useState<boolean>();
	const [discount, setDiscount] = useState<number>(null);

	const {
		availableProducts,
		selectedProductKey,
		selectedPaymentMethod,
		cardInitialized,
		transactionInProcess
	} = useContainerData('paywalls', CheckoutStore, [
		'availableProducts',
		'selectedProductKey',
		'selectedPaymentMethod',
		'cardInitialized',
		'transactionInProcess'
	]);

	useEffect(() => {
		if (selectedProductKey) {
			const product = availableProducts?.find(p => p.product.key === selectedProductKey);
			setCurrentProduct(product);
			setHasTrial(product.product.trialDays > 0);
			setDiscount(product.product.amount - product.product.discountedAmount || null);
		}
	}, [selectedProductKey]);

	const onSubscribeClick = () => {
		trackPaywallClick(PaywallClickTypes.subscribe, selectedProductKey, 'CHECKOUT');
	};

	const names = {
		[LongPaywallProductNames.proMonthlyNoTrial]: <>Sololearn PRO {i18n.t('web-paywall.long.product-period.monthly')}</>,
		[LongPaywallProductNames.proAnnualNoTrial]: <>Sololearn PRO {i18n.t('web-paywall.long.product-period.annual')}</>,
		[LongPaywallProductNames.proAnnualTrial]: <>Sololearn PRO {i18n.t('web-paywall.long.product-period.annual')}</>,
		[LongPaywallProductNames.maxAnnualNoTrial]: <>Sololearn MAX {i18n.t('web-paywall.long.product-period.annual')}</>,
		[LongPaywallProductNames.maxAnnualTrial]: <>Sololearn MAX {i18n.t('web-paywall.long.product-period.annual')}</>
	};

	const getNextPaymentDate = () => {
		const today = new Date();
		const futureDate = new Date(today);
		futureDate.setDate(today.getDate() + currentProduct.product.trialDays);
		const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
		const formattedDate = new Intl.DateTimeFormat(i18n.language, options).format(futureDate);
		return formattedDate;
	};

	const getChargedNow = () => {
		if (hasTrial) {
			return (0).toFixed(2);
		} else {
			return currentProduct.product.discountedAmount.toFixed(2);
		}
	};

	const getBillingFrequency = () => {
		if (currentProduct.product.period === 12) {
			return i18n.t('web-paywall.long.checkout.summary.billing-frequency.year');
		} else if (currentProduct.product.period === 1) {
			return i18n.t('web-paywall.long.checkout.summary.billing-frequency.one-month');
		} else {
			return `${currentProduct.product.period} ${i18n.t('web-paywall.long.checkout.summary.billing-frequency.months')}`;
		}
	};

	const getTrialPeriod = () => {
		if (currentProduct.product.trialDays > 0) {
			return `${currentProduct.product.trialDays} ${i18n.t('web-paywall.long.checkout.summary.trial-period.days')}`;
		} else {
			return i18n.t('web-paywall.long.checkout.summary.trial-period.not-included');
		}
	};

	const getRenewalPeriod = () => {
		if (currentProduct.product.period === 12) {
			return i18n.t('web-paywall.long.checkout.renewal.year');
		} else if (currentProduct.product.period === 1) {
			return i18n.t('web-paywall.long.checkout.renewal.month');
		} else {
			return `${currentProduct.product.period} ${i18n.t('web-paywall.long.checkout.renewal.months')}`;
		}
	};

	const trialMilestones: TimelineMilestone[] = [
		{
			icon: <TimelineMilestone1 />,
			title: 'web-paywall.long.checkout.summary.trial.milestone-1.title',
			description: 'web-paywall.long.checkout.summary.trial.milestone-1.description',
			isCompleted: true
		},
		{
			icon: <TimelineMilestone2 />,
			title: 'web-paywall.long.checkout.summary.trial.milestone-2.title',
			description: 'web-paywall.long.checkout.summary.trial.milestone-2.description',
			isCompleted: false
		},
		{
			icon: <TimelineMilestone3 />,
			title: 'web-paywall.long.checkout.summary.trial.milestone-3.title',
			description: 'web-paywall.long.checkout.summary.trial.milestone-3.description',
			isCompleted: false
		}
	];

	return currentProduct ? <div className="summary">
		<div className="summary-block">
			<div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.plan')}</span>
				<span className="summary-value">{names[currentProduct.meta.name]}</span>
			</div>

			<div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.billing-frequency')}</span>
				<span className="summary-value">{getBillingFrequency()}</span>
			</div>

			<div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.trial-period')}</span>
				<span className="summary-value">{getTrialPeriod()}</span>
			</div>

			{hasTrial && <div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.next-billing-date')}</span>
				<span className="summary-value">{getNextPaymentDate()}</span>
			</div>}

			{hasTrial && <div className="summary-trial">
				<h6>{i18n.t('web-paywall.long.checkout.summary.trial.title')}</h6>
				<Timeline milestones={trialMilestones} />
			</div>}

			<div className="summary-separator"></div>

			<div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.price')}</span>
				<span className="summary-value">${currentProduct.product.amount.toFixed(2)}</span>
			</div>

			{discount && <div className="summary-row">
				<span className="summary-label red">{i18n.t('web-paywall.long.checkout.summary.discount')}</span>
				<span className="summary-value red">- ${discount.toFixed(2)}</span>
			</div>}

			{discount && <div className="summary-row">
				<span className="summary-label">{i18n.t('web-paywall.long.checkout.summary.discounted-price')}</span>
				<span className="summary-value">${currentProduct.product.discountedAmount.toFixed(2)}</span>
			</div>}

			<div className="summary-separator"></div>

			<div className="summary-row strong">
				<span className="summary-label strong">{i18n.t('web-paywall.long.checkout.summary.total')}</span>
				<span className="summary-value strong">${getChargedNow()}</span>
			</div>
		</div>
		<div className="summary-button-container">
			<div className={`summary-button ${selectedPaymentMethod === PaymentMethods.applePay ? 'visible' : 'hidden'}`}>
				<ApplePay />
			</div>
			<div className={`summary-button ${selectedPaymentMethod === PaymentMethods.payPal ? 'visible' : 'hidden'}`}>
				<PaypalPayment />
			</div>
			<div className={`summary-button ${selectedPaymentMethod === PaymentMethods.card ? 'visible' : 'hidden'}`}>
				<PaywallPageButton
					style={{
						background: '#40AF0C',
						height: '48px'
					}}
					onClickCB={onSubscribeClick}
					type={PaywallButtonType.primary}
					action={PaywallButtonAction.subscribe}
					text={hasTrial ? 'web-checkout.start-trial' : 'paywalls.ai-pro.subscribe-now.cta'}
					order={null}
					disabled={!cardInitialized}
					loading={transactionInProcess}
					loadingText={'paywalls.long-flow.purchase-button.purchasing'}
				/>
			</div>
			<div className="summary-note">
				<span>{hasTrial
					? i18n.t(`web-paywall.long.checkout.summary.note.trial`)
					: i18n.t('web-paywall.long.checkout.summary.note.no-trial', { renewalPeriod: getRenewalPeriod() })
				}</span>
				<a href="/terms-of-use" target="_blank">{i18n.t('web-footer.links-terms')}</a>
			</div>
			{currentProduct.meta.billingNotice ? <span className="summary-billing-notice">
				{i18n.t(currentProduct.meta.billingNotice)}
			</span> : null}
		</div>
	</div > : null;
};
