import React, { FC, useEffect } from "react";
import { Container, i18n } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { BraintreeInputField } from "../BraintreeInputField/BraintreeInputField";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './CardPayment.scss';

type Props = {
  layout?: 'vertical' | 'horizontal';
  styles?: {
    container?: React.CSSProperties;
    card?: React.CSSProperties;
    exp?: React.CSSProperties;
    cvv?: React.CSSProperties;
    country?: React.CSSProperties;
    zip?: React.CSSProperties;
    label?: React.CSSProperties;
    input?: React.CSSProperties;
  };
};
export const CardPayment: FC<Props> = ({
  layout = "horizontal",
  styles
}) => {
  useStyles(s);
  const {
    cardError,
    zipCodeRequired,
    transactionInProcess
  } = useContainerData('paywalls', CheckoutStore, [
    'cardError',
    'zipCodeRequired',
    'transactionInProcess'
  ]);
  const { initCard, changeZipCodeRequired, clearCardErrors } = Container.take('paywalls', CheckoutService);

  useEffect(() => {
    initCard();
    clearCardErrors();
  }, []);

  return <div>
    {cardError && <div className="card-error">{cardError}</div>}
    <div className={`card-fields__container ${layout}`} style={styles?.container}>
      <div className="card-fields__card" style={styles?.card}>
        <BraintreeInputField
          styles={{
            label: styles?.label,
            input: styles?.input
          }}
          id="card-number"
          label={i18n.t('web-checkout.card-number-label')}
        />
      </div>
      <div className="card-fields__exp" style={styles?.exp}>
        <BraintreeInputField
          styles={{
            label: styles?.label,
            input: styles?.input
          }}
          id="expiration-date"
          label={i18n.t('web-checkout.expiration-label')}
        />
      </div>
      <div className="card-fields__cvv" style={styles?.cvv}>
        <BraintreeInputField
          styles={{
            label: styles?.label,
            input: styles?.input
          }}
          id="cvv"
          label={i18n.t('web-checkout.security-label')}
        />
      </div>
      <div className="card-fields__country" style={styles?.country}>
        <div className="card-fields__label" style={styles?.label}>{i18n.t('web-checkout.country-label')}</div>
        <select
          value={zipCodeRequired ? '1' : '0'}
          disabled={transactionInProcess}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const val = event.target.value;
            changeZipCodeRequired(val === '0' ? false : true);
          }}
          className="card-fields-country"
          style={styles?.input}
        >
          <option value={'1'}>
            {i18n.t('web-checkout.usa-uk-canada')}
          </option>
          <option value={'0'}>
            {i18n.t('web-checkout.other-countries')}
          </option>
        </select>
      </div>
      <div className={`card-fields__zip ${!zipCodeRequired ? 'hidden' : ''}`} style={styles?.zip}>
        <BraintreeInputField
          styles={{
            label: styles?.label,
            input: styles?.input
          }}
          id="postal-code"
          label={i18n.t('web-checkout.zip-label')}
        />
      </div>
    </div>
  </div>;
};