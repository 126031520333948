import { BehaviorSubject } from "rxjs";
import { Service } from "../../symphony";
import { PaymentProduct } from "../../api/public/subscription-api";
import { LongPaywallProduct, PaymentMethods, WTAPaywallV2Product } from "./paywalls.constants";

@Service()
export class CheckoutStore {
  public cardInitialized = new BehaviorSubject<boolean>(false);
  public paypalInitialized = new BehaviorSubject<boolean>(false);
  public applePayInitialized = new BehaviorSubject<boolean>(false);
  public supportsApplePay = new BehaviorSubject<boolean>(false);
  public transactionInProcess = new BehaviorSubject<boolean>(false);
  public zipCodeRequired = new BehaviorSubject<boolean>(true);
  public cardError = new BehaviorSubject<string>(null);
  public selectedPaymentProduct = new BehaviorSubject<PaymentProduct>(null);

  public selectedProductKey = new BehaviorSubject<string>(null);
  public selectedPaymentMethod = new BehaviorSubject<PaymentMethods>(PaymentMethods.card);
  public availableProducts = new BehaviorSubject<LongPaywallProduct[]>(null);
  public w2aV2Products = new BehaviorSubject<WTAPaywallV2Product[]>(null);
  public checkoutCompleted = new BehaviorSubject<boolean>(false);
  public isLongPaywallCheckout = new BehaviorSubject<boolean>(false);
  public isWTAPaywallV2Checkout = new BehaviorSubject<boolean>(false);
  public scrollToPaymentInfo = new BehaviorSubject<Date>(null);
  public scrollToPlans = new BehaviorSubject<Date>(null);
}
