import React, { FC } from "react";
import s from './WTAPaywallW2Components.scss';
import useStyles from "isomorphic-style-loader/useStyles";

type Props = {
  text: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  styles?: React.CSSProperties;
};

export const WTAPaywallV2Button: FC<Props> = ({ text, className, onClick, disabled, loading, styles = {} }) => {
  useStyles(s);
  return <button
    className={`wtapv2-button ${className || ''} ${disabled ? 'disabled' : ''} ${loading ? 'loading' : ''}`}
    onClick={onClick}
    style={styles}
  >{text}</button>;
};
