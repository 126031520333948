import { firstValueFrom } from "rxjs";
import { getCookie } from "../../../../client/utils/cookies";
import { ExtTrackingOrchestratorApi } from "../../../api/public/ext-tracking-orchestrator-api";
import { Touchpoint } from "../../../api/public/subscription-api";
import { getFingerprintFromCookies } from "../../../shared/public/utilsPublic/utilsPublic";
import { Container, Service, TrackingEventNames, TrackingService } from "../../../symphony";
import { PaywallClickTypes, PaywallNavigationPage } from "../paywalls.constants";
import { PaywallsStore } from "../paywalls.store";
import { v4 as uuidv4 } from 'uuid';

declare const fbq: (
  trackCustom: 'trackCustom',
  eventName: string,
  eventParams: any,
  eventData: any
) => void;

@Service()
export class PaywallsTrackingService {
  private trackingSerivce = Container.take('global', TrackingService);
  private store = Container.take('paywalls', PaywallsStore);
  private fnasService = Container.take('global', ExtTrackingOrchestratorApi);

  public trackImpression = (webPageType?: 'PAYWALL' | 'CHECKOUT') => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    if (touchpoint === Touchpoint.web2app) {
      const eventId = uuidv4();
      const eventName = 'WTAPaywallImpressionCoding';
      const fbc = getCookie('_fbc');
      const externalId = getFingerprintFromCookies();
      if ((window as any).fbq !== undefined) {
        fbq("trackCustom", eventName, {}, {
          eventID: eventId
        });
      }
      firstValueFrom(this.fnasService.sendFBEvent({ eventName, eventId, fbc, externalId }));
    }
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallImpression,
      eventParams: {
        touchpoint,
        webPageType: webPageType || (page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT'),
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '3-0-0',
        appVersion: '0.0.0'
      },
      immediate: true
    });
  };

  public trackClick = (clickType: PaywallClickTypes, value?: string, webPageType?: 'PAYWALL' | 'CHECKOUT') => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallClick,
      eventParams: {
        value: value || null,
        touchpoint,
        clickType,
        webPageType: webPageType || (page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT'),
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '3-0-0',
        appVersion: '0.0.0'
      },
      immediate: true
    });
  };

  public trackSectionView = (section: string, sectionOrder: number) => {
    const touchpoint = this.store.currentTouchpoint.value;
    const { key, resolvedVariables } = this.store.paywallData.value;
    const { page, step } = this.store.paywallNavigation.value;
    this.trackingSerivce.tracking$.next({
      eventName: TrackingEventNames.paywallSectionView,
      eventParams: {
        touchpoint,
        webPageType: page === PaywallNavigationPage.paywall ? 'PAYWALL' : 'CHECKOUT',
        orderNumber: step,
        resolvedVariables: resolvedVariables || {},
        paywallKey: key,
        version: '1-0-0',
        appVersion: '0.0.0',
        section,
        sectionOrder: sectionOrder.toString()
      },
      immediate: true
    });
  };
}
