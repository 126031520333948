import React, { FC, RefObject, useEffect } from "react";
import s from "./LongPaywallProducts.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container, i18n } from "../../../../../../symphony";
import { Checkmark, CodePattern1, CodePattern2, MaxIcon, ProIcon } from "../../../ui/icons";
import { CheckoutService } from "../../../../services/checkout.service";
import { LongPaywallProductMeta, LongPaywallProductNames, PaywallClickTypes } from "../../../../paywalls.constants";
import { useInView } from "../../../../paywalls.utils";
import { PaywallsService } from "../../../../services/paywalls.service";

type Props = {
	data: {
		products: LongPaywallProductMeta[];
	};
	style: React.CSSProperties;
	sectionOrder: number;
};

export const LongPaywallProducts: FC<Props> = ({ data, style, sectionOrder }) => {
	useStyles(s);
	const [ref, isInView] = useInView({
		threshold: 0.7
	});
	const {
		trackSectionView,
		trackPaywallClick,
	} = Container.take('paywalls', PaywallsService);
	const { selectLongPaywallProduct } = Container.take('paywalls', CheckoutService);

	useEffect(() => {
		isInView && trackSectionView('longProducts', sectionOrder);
	}, [isInView]);

	const proIcon = (<span className="icon pro"><ProIcon /></span>);
	const maxIcon = (<div className="icon max"><MaxIcon /></div>);

	const productName = (name: LongPaywallProductNames) => {
		const billingCycle = {
			[LongPaywallProductNames.proMonthlyNoTrial]: 'web-paywall.long.product-period.monthly',
			[LongPaywallProductNames.proAnnualNoTrial]: 'web-paywall.long.product-period.annual',
			[LongPaywallProductNames.proAnnualTrial]: 'web-paywall.long.product-period.annual',
			[LongPaywallProductNames.maxAnnualNoTrial]: 'web-paywall.long.product-period.annual',
			[LongPaywallProductNames.maxAnnualTrial]: 'web-paywall.long.product-period.annual'
		};

		const icons = {
			[LongPaywallProductNames.proMonthlyNoTrial]: proIcon,
			[LongPaywallProductNames.proAnnualNoTrial]: proIcon,
			[LongPaywallProductNames.proAnnualTrial]: proIcon,
			[LongPaywallProductNames.maxAnnualNoTrial]: maxIcon,
			[LongPaywallProductNames.maxAnnualTrial]: maxIcon
		};

		return <div className="products-name">
			<div className="products-name-first-line">Sololearn {icons[name]}</div>
			<span>{i18n.t(billingCycle[name])}</span>
		</div>;
	};

	const colorPallete = {
		[LongPaywallProductNames.proMonthlyNoTrial]: 'blue',
		[LongPaywallProductNames.proAnnualNoTrial]: 'orange',
		[LongPaywallProductNames.proAnnualTrial]: 'orange',
		[LongPaywallProductNames.maxAnnualNoTrial]: 'purple',
		[LongPaywallProductNames.maxAnnualTrial]: 'purple'
	};

	const onButtonClick = (productKey: string) => {
		trackPaywallClick(PaywallClickTypes.cta, productKey);
		selectLongPaywallProduct(productKey);
	};

	return <div ref={ref as RefObject<HTMLDivElement>} style={style} className="products">
		<div className="products-items">
			{data.products.map((p) => <div className={`products-item ${colorPallete[p.name]}`} key={p.key}>
				{productName(p.name)}

				<span className={`products-price ${p.discountedPrice ? 'strikethrough' : ''}`}>
					${(p.originalPrice / p.period).toFixed(2)} {!p.discountedPrice && <> USD<span>/{i18n.t(p.priceSuffix)}</span></>}
				</span>
				{p.discountedPrice ? <span className="products-price discounted">
					${(p.discountedPrice / p.period).toFixed(2)} USD<span>/{i18n.t(p.priceSuffix)}</span>
				</span> : null}
				<span className="products-billing-cycle" dangerouslySetInnerHTML={{ __html: i18n.t(p.billingInfo) }}></span>
				<div className={`products-features`}>
					{p.features.map((f, i) => <div className={`products-feature ${i === p.features.length - 1 ? 'last' : ''}`} key={(f)}>
						<p className="products-feature-title">{i18n.t(f)}</p>
						<div className={`products-feature-checkmark-container ${colorPallete[p.name]} ${i === 0 ? 'first' : ''} ${i === p.features.length - 1 ? 'last' : ''}`}>
							<div className="products-feature-checkmark"><Checkmark /></div>
						</div>
					</div>)}
				</div>
				<button
					onClick={() => onButtonClick(p.key)}
					className={`products-cta ${colorPallete[p.name]}`}
				>{i18n.t(p.cta)}</button>
				{p.tag && <div className="products-tag">
					<span>{i18n.t(p.tag)}</span>
				</div>}
			</div>)}
		</div>
		<div className="products-pattern right">
			<CodePattern1 />
		</div>
		<div className="products-pattern left">
			<CodePattern2 />
		</div>
	</div>;
};