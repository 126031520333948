import React, { FC } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './BraintreeInputField.scss';
import { useContainerData } from '../../../../../shared/public/SlHooks/useContainerData';
import { CheckoutStore } from '../../../checkout.store';

type BraintreeInputFieldProps = {
  label: string;
  id: string;
  styles?: {
    label?: React.CSSProperties;
    input?: React.CSSProperties;
  };
};

export const BraintreeInputField: FC<BraintreeInputFieldProps> = ({
  label, id, styles
}) => {
  useStyles(s);

  const {
    transactionInProcess
  } = useContainerData('paywalls', CheckoutStore, [
    'transactionInProcess'
  ]);

  return <div className="sl-br-input-wrapper">
    <div className="sl-br-input-wrapper__label" style={styles?.label}>{label}</div>
    <div
      className={`sl-br-input-wrapper__input ${transactionInProcess ? 'disabled' : ''}`}
      id={id}
      style={styles?.input}
    ></div>
  </div>;
};
