import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTAPaywallV2Payment.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { PaymentMethodItem, PaymentMethods, PaywallClickTypes } from "../../../paywalls.constants";
import { PaymentMethodIcon } from "../../ui/icons";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallV2RadioButton } from "../components/WTAPaywallV2RadioButton";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { CheckoutService } from "../../../services/checkout.service";
import { Container } from "../../../../../symphony";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { PaypalPayment } from "../../checkout/PaypalPayment/PaypalPayment";
import { ApplePay } from "../../checkout/ApplePay/ApplePay";
import { CardPayment } from "../../checkout/CardPayment/CardPayment";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {};
  sectionOrder: number;
};

const allPaymentMethods: PaymentMethodItem[] = [
  {
    method: PaymentMethods.applePay, title: 'Apple Pay', icon: PaymentMethodIcon.applePay
  },
  {
    method: PaymentMethods.payPal, title: 'PayPal', icon: PaymentMethodIcon.payPal
  },
  {
    method: PaymentMethods.card, title: 'Credit Card', icon: <>{PaymentMethodIcon.visa}{PaymentMethodIcon.master}{PaymentMethodIcon.amex}{PaymentMethodIcon.discover}</>
  }
];

export const WTAPaywallV2Payment: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { selectPaymentMethod, submitCardPayment } = Container.take('paywalls', CheckoutService);
  const { trackClick, trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });
  const {
    supportsApplePay,
    selectedPaymentMethod,
    selectedProductKey,
    w2aV2Products,
    cardInitialized,
    transactionInProcess
  } = useContainerData('paywalls', CheckoutStore, [
    'supportsApplePay',
    'selectedPaymentMethod',
    'selectedProductKey',
    'w2aV2Products',
    'cardInitialized',
    'transactionInProcess'
  ]);

  useEffect(() => {
    isInView && trackSectionView('payment', sectionOrder);
  }, [isInView]);

  const onCardPaymentClick = () => {
    trackClick(PaywallClickTypes.subscribe, 'card');
    submitCardPayment();
  };

  const onPaymentMethodClick = (method: PaymentMethods) => {
    trackClick(PaywallClickTypes.paymentMethod, method);
    selectPaymentMethod(method);
  };

  const getProductPrice = () => {
    const product = w2aV2Products?.find(p => p.product.key === selectedProductKey);
    return product.meta.priceType === 'full'
      ? `Pay $${product.product.discountedAmount.toFixed(2)}`
      : 'Subscribe';
  };

  return selectedProductKey ? <div ref={ref as RefObject<HTMLDivElement>} className="payment-methods">
    {allPaymentMethods.map(m => <WTAPayawllV2Card
      key={m.method}
      styles={{ padding: '0' }}
      className={`payment-methods-item ${(m.method === PaymentMethods.applePay && !supportsApplePay) ? 'hidden' : ''}`}
    >
      <div
        onClick={() => { onPaymentMethodClick(m.method); }}
        className="payment-methods-item-header"
      >
        <WTAPaywallV2RadioButton className="payment-methods-item-radio-button" selected={selectedPaymentMethod === m.method} />
        <WTAPaywallV2Typography className="payment-methods-item-title" text={m.title} level="h2" />
        <div className={`payment-methods-item-icon ${m.method}`}>{m.icon}</div>
      </div>
      <div className={`payment-methods-item-body ${selectedPaymentMethod === m.method ? 'visible' : 'hidden'}`}>
        <div className={`payment-methods-item-body-total ${m.method === PaymentMethods.card ? 'bordered' : ''}`}>
          <WTAPaywallV2Typography level="h3" text="Total today:" />
          <WTAPaywallV2Typography level="h3" text={`$${w2aV2Products.find(p => p.product.key === selectedProductKey).product.discountedAmount}`} />
        </div>
        {m.method === PaymentMethods.card && <CardPayment
          styles={{
            container: {
              marginBottom: '0',
              rowGap: '10px',
              gridTemplateAreas: '"card card" "exp cvv" "country country" "zip zip"'
            },
            label: {
              color: '#818181',
              fontSize: '16px',
              fontWeight: '600'
            },
            input: {
              backgroundColor: '#FFFCFB',
              borderRadius: '7px',
              border: '1px solid #A2A2A2',
            }
          }}
          layout="vertical"
        />}
        <div className="payment-methods-item-body-button-container">
          {m.method === PaymentMethods.card && selectedProductKey && <WTAPaywallV2Button
            text={`${getProductPrice()}`}
            onClick={onCardPaymentClick}
            disabled={!cardInitialized}
            loading={transactionInProcess}
          />}
          {m.method === PaymentMethods.payPal && <PaypalPayment />}
          {m.method === PaymentMethods.applePay && <ApplePay />}
        </div>
      </div>
    </WTAPayawllV2Card>)}
  </div> : null;
};
