import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import { IStreakWeekDay } from '../../../../symphony';

import { StreakWeekDay } from '../StreakWeekDay/StreakWeekDay';

import s from './StreakWeek.scss';

interface IStreakWeekData {
	days: IStreakWeekDay[];
}
export const StreakWeek: React.FC<IStreakWeekData> = React.memo(({ days }) => {
	useStyles(s);
	return (
		<div sl-test-data="cmpStreamWeek" className="streak-week">
			{days.map((day) => (
				<StreakWeekDay {...day} key={day.id} />
			))}
		</div>
	);
});
