import React, { FC, RefObject, useEffect } from "react";
import s from "./WTAPaywallV2Reviews.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallIcons } from "../components/WTAPaywallV2Icons";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { Container } from "../../../../../symphony";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title: string;
    reviews: {
      reviewer: string;
      review: string;
      avatar: string;
    }[];
  };
  sectionOrder: number;
};

export const WTAPaywallV2Reviews: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { title, reviews } = data;
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('reviews', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-reviews">
    <WTAPaywallV2Typography
      level="h1"
      text={title}
      className="wtapv2-reviews-title"
    />
    <div className="wtapv2-reviews-container">
      {reviews.map((review, i) => <WTAPayawllV2Card
        key={review.avatar + i}
        className="wtapv2-reviews-item"
        styles={{ padding: '24px 20px', borderRadius: '16px' }}
      >
        <div className="wtapv2-reviews-header">
          <div className="wtapv2-reviews-item-avatar">
            <img src={review.avatar} alt="avatar" />
          </div>
          <div className="wtapv2-reviews-header-content">
            <WTAPaywallV2Typography
              level="h3"
              text={review.reviewer}
            />
            <WTAPaywallV2Typography
              level="paragraph"
              text="recommends Sololearn"
              className="wtapv2-reviews-item-reviewer"
            />
            <div className="wtapv2-reviews-star">
              {Array(5).fill(0).map((_, index) => <WTAPaywallIcons.reviewStar key={index} />)}
            </div>
          </div>
        </div>
        <WTAPaywallV2Typography
          level="paragraph"
          text={`"${review.review}"`}
        />
      </WTAPayawllV2Card>)}
    </div>
  </div>;
};
