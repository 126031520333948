import React, { FC } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallIcons } from "./WTAPaywallV2Icons";

type Props = {
	items: React.ReactNode[];
	className?: string;
	size: 'small' | 'large';
};

export const WTAPaywallV2List: FC<Props> = ({
	items, className, size
}) => {
	useStyles(s);
	return <div className={`wtapv2-list ${className || ''} ${size}`}>
		{items.map((item, index) => <div key={index} className="wtapv2-list-item">
			<div className="wtapv2-list-item-icon">{<WTAPaywallIcons.checkmark />}</div>
			<div className="wtapv2-list-item-text">{item}</div>
		</div>)}
	</div>;
};
