import React, { FC, ReactNode } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";

type Props = {
	text: string;
	icon: ReactNode;
	theme: 'green' | 'yellow' | 'blue' | 'purple';
	className?: string;
};

export const WTAPaywallV2Label: FC<Props> = ({
	text, icon, theme, className
}) => {
	useStyles(s);
	return <div className={`wtapv2-label ${theme} ${className || ''}`}>
		{icon ? <div className="wtapv2-label__icon">{icon}</div> : null}
		<span className={`wtapv2-label__text ${theme}`}>{text}</span>
	</div>;
};
