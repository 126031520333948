import React, { FC, RefObject, useEffect } from "react";
import s from "./WTAPaywallV2Stats.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title: string;
    items: {
      percentage: string;
      text: string;
    }[];
  };
  sectionOrder: number;
};

export const WTAPaywallV2Stats: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { title, items } = data;
  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('stats', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-stats">
    <WTAPaywallV2Typography
      level="h1"
      text={title}
    />
    <div className="wtapv2-stats-list">
      {items.map(
        (item, index) => (<div key={index} className="wtapv2-stats-item">
          <div className="wtapv2-stats-item-content">
            <WTAPaywallV2Typography level="h1" text={item.percentage} />
            <WTAPaywallV2Typography level="paragraph" text={item.text} />
          </div>
        </div>))}
    </div>
  </div>;
};
