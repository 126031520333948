import React, { FC, useEffect } from "react";
import s from "./WTAPaywallV2.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { PaywallComponent, PaywallComponentType, PaywallPage } from "../../../../api/public/subscription-api";
import { WTAPaywallV2Summary } from "./sections/WTAPaywallV2Summary";
import { WTAPaywallV2Stats } from "./sections/WTAPaywallV2Stats";
import { WTAPaywallV2Reviews } from "./sections/WTAPaywallV2Reviews";
import { WTAPaywallV2Plans } from "./sections/WTAPaywallV2Plans";
import { WTAPaywallV2SelectedPlan } from "./sections/WTAPaywallV2SelectedPlan";
import { WTAPaywallV2PaymentInfo } from "./sections/WTAPaywallV2PaymentInfo";
import { WTAPaywallV2Payment } from "./sections/WTAPaywallV2Payment";
import { WTAPaywallV2Footer } from "./sections/WTAPaywallV2Footer";
import { WTAPaywallV2Congrats } from "./sections/WTAPaywallV2Congrats";
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";
import { CheckoutStore } from "../../checkout.store";
import { Container } from "../../../../symphony";
import { CheckoutService } from "../../services/checkout.service";
import { WTAPaywallV2Discount } from "./sections/WTAPaywallV2Discount";

type Props = {
  page: PaywallPage;
};

export const WTAPaywallV2: FC<Props> = ({ page }) => {
  useStyles(s);
  const { paywallData } = useContainerData('paywalls', PaywallsStore, ['paywallData']);
  const { selectedProductKey, w2aV2Products, checkoutCompleted } = useContainerData('paywalls', CheckoutStore, [
    'selectedProductKey', 'w2aV2Products', 'checkoutCompleted'
  ]);
  const {
    defineWTAPaywallV2Products,
    setSelectedProductKey,
    setSelectedProduct
  } = Container.take('paywalls', CheckoutService);

  useEffect(() => {
    if (selectedProductKey && w2aV2Products) {
      setSelectedProduct(w2aV2Products.find(p => p.product.key === selectedProductKey).product);
    }
  }, [selectedProductKey, w2aV2Products]);

  useEffect(() => {
    if (checkoutCompleted) {

    }
  }, [checkoutCompleted]);

  useEffect(() => {
    const plansData = page.components.find(c => c.type === PaywallComponentType.w2aV2Plans)?.data;
    defineWTAPaywallV2Products(plansData.products, paywallData.products);
    setSelectedProductKey(plansData.selectedProductKey);
  }, []);

  const getComponent = (component: PaywallComponent, i: number) => {
    let definedComponent = null;
    switch (component.type) {
      case PaywallComponentType.w2aV2Discount:
        definedComponent = <WTAPaywallV2Discount data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Summary:
        definedComponent = <WTAPaywallV2Summary data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2SelectedPlan:
        definedComponent = <WTAPaywallV2SelectedPlan data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2PaymentInfo:
        definedComponent = <WTAPaywallV2PaymentInfo data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Payment:
        definedComponent = <WTAPaywallV2Payment data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Plans:
        definedComponent = <WTAPaywallV2Plans data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Stats:
        definedComponent = <WTAPaywallV2Stats data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Reviews:
        definedComponent = <WTAPaywallV2Reviews data={component.data} sectionOrder={i + 1} />;
        break;
      case PaywallComponentType.w2aV2Footer:
        definedComponent = <WTAPaywallV2Footer data={component.data} sectionOrder={i + 1} />;
        break;
    }
    return <div
      key={component.type + i}
      style={component.style || {}}
    >{definedComponent}</div>;
  };

  return <div className="wtapv2">
    {page.components?.map((c, i) => getComponent(c, i))}
    {checkoutCompleted && <WTAPaywallV2Congrats />}
  </div>;
};
