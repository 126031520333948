import { CheckoutService } from "../../_paywalls/private/services/checkout.service";
import { PaywallsService } from "../../_paywalls/private/services/paywalls.service";
import { Touchpoint } from "../../api/public/subscription-api";
import { Container, Service, history } from "../../symphony";

@Service()
export class PricingService {
	private paywallService = Container.take('paywalls', PaywallsService);
	private checkoutService = Container.take('paywalls', CheckoutService);

	public showPricingPaywall = () => {
		this.paywallService.openPaywall({
			touchpoint: Touchpoint.pricing,
			props: {}
		}, {
			onClose: () => {
				try {
					history.back();
					setTimeout(() => {
						if (location.href.includes('/pricing')) {
							history.push('/');
						}
					}, 0);
				} catch (err) {
					history.push('/');
				}
			}
		});
	};

	public closePricingPaywall = () => {
		this.paywallService.cleanupPaywallState();
	};

	public selectProduct = (productKey: string) => {
		this.checkoutService.selectLongPaywallProduct(productKey);
	};
}