import React, { FC } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";

type Props = {
	text: React.ReactNode;
	level: 'h1' | 'h2' | 'h3' | 'paragraph' | 'caption';
	className?: string;
};

export const WTAPaywallV2Typography: FC<Props> = ({
	text, level, className
}) => {
	useStyles(s);

	const typographyMapping = {
		'h1': <h1 className={`wtapv2-typography ${level} ${className || ''}`}>{text}</h1>,
		'h2': <h2 className={`wtapv2-typography ${level} ${className || ''}`}>{text}</h2>,
		'h3': <h3 className={`wtapv2-typography ${level} ${className || ''}`}>{text}</h3>,
		'paragraph': <p className={`wtapv2-typography ${level} ${className || ''}`}>{text}</p>,
		'caption': <span className={`wtapv2-typography ${level} ${className || ''}`}>{text}</span>
	};

	return typographyMapping[level];
};
