import { NavigationItem, i18n } from '../../symphony';
import {
	IFooterCatalog,
	INavConfig,
	NavItemActionType,
} from './global-interface';

export const SlNavigationContext = 'mf_slNavigation_container_context';

export const getNavConfigs = (): INavConfig => ({
	logoConfig: {
		link: '/',
	},
	itemsConfig: [
		{
			key: NavigationItem.Courses,
			name: i18n.t('web-navigation.nav-item-courses'),
			actionType: NavItemActionType.Catalog,
			link: '/learn',
			loggedIn: true,
			nonLoggedIn: true,
			trackElement: 'courses',
			newTrackElement: 'CATALOG',
		},
		{
			key: NavigationItem.CodeCompiler,
			name: i18n.t('web-navigation.nav-item-code-compiler'),
			actionType: NavItemActionType.Catalog,
			link: '/compiler-playground',
			loggedIn: false,
			nonLoggedIn: true,
			trackElement: 'compiler',
			newTrackElement: 'CODE PLAYGROUND',
		},
		{
			key: NavigationItem.Leaderboard,
			name: i18n.t('web-navigation.nav-item-leaderboard'),
			actionType: NavItemActionType.InternalLink,
			link: '/leaderboard-league',
			loggedIn: true,
			nonLoggedIn: false,
			trackElement: 'leaderboard',
			newTrackElement: 'LEADERBOARD',
		},
		{
			key: NavigationItem.CodeBits,
			name: i18n.t('web-navigation.nav-item-code-bits'),
			actionType: NavItemActionType.InternalLink,
			link: '/codes',
			loggedIn: true,
			nonLoggedIn: false,
			trackElement: 'codeplayground',
			newTrackElement: 'CODE PLAYGROUND',
		},
		{
			key: NavigationItem.Discuss,
			name: i18n.t('web-navigation.nav-item-discuss'),
			actionType: NavItemActionType.InternalLink,
			link: '/discuss',
			loggedIn: true,
			nonLoggedIn: true,
			trackElement: 'discuss',
			newTrackElement: 'DISCUSS',
		},
		{
			key: NavigationItem.Blog,
			name: i18n.t('web-navigation.nav-item-blog'),
			actionType: NavItemActionType.ExternalLink,
			link: 'https://sololearn.com/blog',
			loggedIn: true,
			nonLoggedIn: false,
			trackElement: 'blog',
			newTrackElement: 'BLOG',
		},
		{
			key: NavigationItem.Pricing,
			name: i18n.t('web-navigation.nav-item-pricing'),
			actionType: NavItemActionType.ExternalLink,
			link: '/pricing',
			loggedIn: false,
			nonLoggedIn: true,
			trackElement: 'pricing',
			newTrackElement: 'PRICING'
		},
		{
			key: NavigationItem.Teams,
			name: i18n.t('web-navigation.nav-item-teams'),
			actionType: NavItemActionType.InternalLink,
			link: '/teams',
			loggedIn: false,
			nonLoggedIn: true,
			trackElement: 'teams',
			newTrackElement: 'TEAMS',
		},
	],
});

export const getFooterCatalogComunity = (): IFooterCatalog => ({
	title: i18n.t('web-footer.community-catalog-title'),
	items: [
		{
			title: i18n.t('web-footer.community-catalog-discuss'),
			path: '/discuss',
			alias: 'discuss',
			typeId: '4',
			isExternalNavigation: false,
		},
		{
			title: i18n.t('web-footer.community-catalog-code-bits'),
			path: '/codes',
			alias: 'code playground',
			typeId: '4',
			isExternalNavigation: false,
		},
		{
			title: i18n.t('web-footer.community-catalog-leadearboard'),
			path: '/leaderboard-league',
			alias: 'leaderboard',
			typeId: '4',
		},
		{
			title: i18n.t('web-footer.community-catalog-blog'),
			path: '/blog',
			alias: 'blog',
			typeId: '4',
			isExternalNavigation: true,
		},
	],
});
