import { Observable } from 'rxjs';

export interface ISubscriptionApi {
  getUserStats: () => Observable<IUserStatsResponse>;
}

export interface IUserStatsResponse {
  userId: number;
  noOfCompletedLessons2023: number;
  noOfXps2023: number;
  maxStreak2023: number;
  minutesActive2023: number;
  topLearnerPercentage2023: number;
}

export enum Touchpoint {
  navigation = 'navigation',
  selfProfile = 'self-profile',
  otherProfile = 'other-profile',
  footer = 'footer',
  heartsCourseEnough = 'hearts-course-enough',
  heartsCourseOut = 'hearts-course-out',
  heartsLessonEnough = 'hearts-lesson-enough',
  heartsLessonOut = 'hearts-lesson-out',
  kodieLesson = 'kodie-lesson',
  kodiePlayground = 'kodie-playground',
  blockedLearning = 'blocked-learning',
  tiy = 'tiy',
  lesson = 'lesson',
  codeProject = 'code-project',
  aiChatPlayground = 'ai-chat-playground',
  moduleQuiz = 'module-quiz',
  codeRepo = 'code-repo',
  booster = 'booster',
  codeCoach = 'code-coach',
  onboarding = 'onboarding',
  lessonComplete = 'lesson-complete',
  comms = 'comms',
  web2app = 'web-to-app',
  pricing = 'pricing'
}

export enum PaywallPageTypes {
  generic = 'generic',
  webToApp = 'webToApp',
  long = 'long',
  wtaV2 = 'wtaV2'
}

export enum PaywallButtonType {
  secondary = 'secondary',
  primary = 'primary',
  wta = 'wta'
}

export enum PaywallButtonAction {
  next = 'next',
  checkout = 'checkout',
  subscribe = 'subscribe',
  close = 'close',
  finalize = 'finalize'
}

export type PaywallButton = {
  type: PaywallButtonType;
  action: PaywallButtonAction;
  text: string;
};

export enum PaywallComponentType {
  benefits = 'benefits',
  fiveStarReviews = '5-star-reviews',
  comparison = 'comparison',
  appleAward = 'apple-award',
  timeline = 'timeline',
  title = 'title',
  product = 'product',
  discountUnlocked = 'discountUnlocked',
  w2aProducts = 'w2aProducts',
  w2aWhatYouGet = 'w2aWhatYouGet',
  w2aFAQ = 'w2aFAQ',
  w2aReviews = 'w2aReviews',
  lpCampaign = 'lpCampaign',
  lpHeader = 'lpHeader',
  lpHeadings = 'lpHeadings',
  lpProducts = 'lpProducts',
  lpAwards = 'lpAwards',
  lpComparison = 'lpComparison',
  lpReviews = 'lpReviews',
  lpFAQ = 'lpFAQ',
  lpPartnership = 'lpPartnership',
  w2aV2Summary = 'w2aV2Summary',
  w2aV2SelectedPlan = 'w2aV2SelectedPlan',
  w2aV2PaymentInfo = 'w2aV2PaymentInfo',
  w2aV2Payment = 'w2aV2Payment',
  w2aV2Plans = 'w2aV2Plans',
  w2aV2Stats = 'w2aV2Stats',
  w2aV2Reviews = 'w2aV2Reviews',
  w2aV2Footer = 'w2aV2Footer',
  w2aV2Discount = 'w2aV2Discount'
}

export enum PaywallHeaderComponentType {
  ratings = 'ratings',
  countdown = 'countdown'
}

export type PaywallHeaderComponent = {
  type: PaywallHeaderComponentType;
  text?: string;
  countdownSeconds?: number;
};

export type PaywallComponent = {
  type: PaywallComponentType;
  style: React.CSSProperties;
  data: any;
};

export type PaywallPage = {
  order: number;
  type: PaywallPageTypes;
  title: string;
  subtitle: string;
  components: PaywallComponent[];
  footerButtons: PaywallButton[];
  headerVersion: 1 | 2;
  headerComponents?: PaywallHeaderComponent[];
  styles?: {
    header?: React.CSSProperties;
    body?: React.CSSProperties;
    title?: React.CSSProperties;
  };
};

export type PaywallData = {
  metadata: { theme: PaywallTheme; };
  version: string;
  data: PaywallPage[];
  key: string;
  resolvedVariables: string;
  products: PaymentProduct[];
};

export type PaymentProduct = {
  key: string;
  amount: number;
  discountedAmount: number;
  name: string;
  trialDays: number;
  period: number;
};

export enum PlanConfigKey {
  splash = 'splash',
  profileDesign = 'profile_design',
  showManageSubscriptions = 'show_manage_subscriptions',
  showLaunchPaywall = 'show_launch_paywall',
  showSettingsGoPro = 'show_settings_go_pro',
  show3DotMenuGoPro = 'show_3_dot_menu_go_pro',
  showNavigationGoPro = 'show_navigation_go_pro',
  showProfileGoPro = 'show_profile_go_pro',
  showLessonCompletePaywall = 'show_lesson_complete_paywall',
  hearts = 'hearts',
  bugHunter = 'bug_hunter'
}

export type SubscriptionPlanFeatures = {
  [PlanConfigKey.splash]: 'regular' | 'pro';
  [PlanConfigKey.profileDesign]: 'regular' | 'pro';
  [PlanConfigKey.showManageSubscriptions]: boolean;
  [PlanConfigKey.showLaunchPaywall]: boolean;
  [PlanConfigKey.showSettingsGoPro]: boolean;
  [PlanConfigKey.show3DotMenuGoPro]: boolean;
  [PlanConfigKey.showNavigationGoPro]: boolean;
  [PlanConfigKey.showProfileGoPro]: boolean;
  [PlanConfigKey.showLessonCompletePaywall]: boolean;
  [PlanConfigKey.hearts]: 'limited' | 'unlimited';
  [PlanConfigKey.bugHunter]: 'limited' | 'unlimited';
};

export type SubscriptionPlanConfig = {
  plan: 'basic' | 'codingpro' | 'aipro';
  common: SubscriptionPlanFeatures;
  tracks: {
    ai: SubscriptionPlanFeatures;
    coding: SubscriptionPlanFeatures;
  };
  id: number;
  end_date: string;
};

export enum PaywallTheme {
  blue = 'blue',
  gold = 'gold',
  purple = 'purple',
  purple2 = 'purple2'
}