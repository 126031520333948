import React, { FC } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Timeline.scss';
import { i18n } from "../../../../../../symphony";

export type TimelineMilestone = {
	icon: React.ReactNode;
	title: string;
	description: string;
	isCompleted: boolean;
};

type TimelineProps = {
	milestones: TimelineMilestone[];
};

export const Timeline: FC<TimelineProps> = ({
	milestones
}) => {
	useStyles(s);
	return <div className="long-paywall-timeline">
		<div className="long-paywall-timeline-ribbon"></div>
		<div className="long-paywall-timeline-content">
			{milestones.map(m => (
				<div key={m.title} className="long-paywall-timeline-milestone">
					<div className={`long-paywall-timeline-icon ${m.isCompleted ? 'completed' : ''}`}>
						{m.icon}
					</div>
					<div className="long-paywall-timeline-milestone-info">
						<p className="long-paywall-timeline-title">{i18n.t(m.title)}</p>
						<p className="long-paywall-timeline-description">{i18n.t(m.description)}</p>
					</div>
				</div>
			))}
		</div>
	</div>;
};
