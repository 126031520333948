import { FC, useEffect } from "react";
import s from "./Pricing.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Container } from "../../symphony";
import { PricingService } from "../private/pricing.service";
import { useQuery } from "../../shared/public/utilsPublic/utilsPublic";

type Props = {};

export const Pricing: FC<Props> = () => {
	useStyles(s);
	const query = useQuery();

	const {
		showPricingPaywall,
		closePricingPaywall,
		selectProduct
	} = Container.take('global', PricingService);

	useEffect(() => {
		showPricingPaywall();
		if (query.productkey) {
			selectProduct(query.productkey);
		}
		return () => {
			setTimeout(() => {
				closePricingPaywall();
			}, 0);
		};
	}, []);

	return null;
};
