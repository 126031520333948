import React, { FC } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";

type Props = {
  children: React.ReactNode;
  className?: string;
  styles?: React.CSSProperties;
  onClick?: () => void;
};

export const WTAPayawllV2Card: FC<Props> = ({
  children,
  className,
  styles = {},
  onClick = () => { }
}) => {
  useStyles(s);
  return <div style={styles} className={`wtapv2-card ${className || ''}`} onClick={onClick}>{children}</div>;
};