import { CourseCategory, i18n } from '../../symphony';
import {
	ActionButtonTypes,
	ActionType,
	ILockedCardModalData,
	QuizFeedbackType,
} from './global-interface';
import { getCorrectAnswerResultText } from './utils/helpers';
import { MaterialType, ScreenName } from '../../api/private/learn-engine-api/learn-engine-api.interface';

import fire3Animation from './animations/fire_3.json';
import fire5Animation from './animations/fire_5.json';

export const SlLEContext = 'mf_slLE_container_context';

export const COURSE_ICON_HOST = 'https://uploads.sololearn.com/uploads';

export const getFooterButtons = () => Object.freeze({
	[ActionType.ToNextPage]: {
		title: i18n.t('web-le.next-page-button'),
		disabled: false,
		action: ActionType.ToNextPage,
	},
	[ActionType.ByPassLesson]: {
		title: i18n.t('web-le.bypass-lesson-button'),
		disabled: false,
		action: ActionType.ByPassLesson,
		type: ActionButtonTypes.primary,
	},
	[ActionType.CompleteLesson]: {
		title: i18n.t('web-le.complete-lesson-button'),
		disabled: false,
		action: ActionType.CompleteLesson,
	},
	[ActionType.CheckAnswer]: {
		title: i18n.t('web-le.check-answer-button'),
		disabled: true,
		action: ActionType.CheckAnswer,
	},
	[ActionType.TryAgain]: {
		title: i18n.t('web-le.try-again-button'),
		disabled: false,
		action: ActionType.TryAgain,
		type: ActionButtonTypes.primary,
	},
	[ActionType.ToPreviousPage]: {
		title: i18n.t('web-le.to-previous-page-button'),
		disabled: false,
		action: ActionType.ToPreviousPage,
	},
	[ActionType.OpenUnlockQuizAnswerModal]: {
		title: null,
		disabled: false,
		action: ActionType.OpenUnlockQuizAnswerModal,
	},
});

const getQuizFeedbackTexts = () => [
	i18n.t('web-le.quiz-positive-feedback0'),
	i18n.t('web-le.quiz-positive-feedback1'),
	i18n.t('web-le.quiz-positive-feedback2'),
	i18n.t('web-le.quiz-positive-feedback3'),
	i18n.t('web-le.quiz-positive-feedback4'),
	i18n.t('web-le.quiz-positive-feedback5'),
	i18n.t('web-le.quiz-positive-feedback6'),
	i18n.t('web-le.quiz-positive-feedback7'),
	i18n.t('web-le.quiz-positive-feedback8'),
	i18n.t('web-le.quiz-positive-feedback9'),
	i18n.t('web-le.quiz-positive-feedback10'),
	i18n.t('web-le.quiz-positive-feedback11'),
	i18n.t('web-le.quiz-positive-feedback12'),
	i18n.t('web-le.quiz-positive-feedback13'),
	i18n.t('web-le.quiz-positive-feedback14'),
	i18n.t('web-le.quiz-positive-feedback15'),
	i18n.t('web-le.quiz-positive-feedback16'),
	i18n.t('web-le.quiz-positive-feedback17'),
	i18n.t('web-le.quiz-positive-feedback18'),
	i18n.t('web-le.quiz-positive-feedback19'),
	i18n.t('web-le.quiz-positive-feedback20'),
	i18n.t('web-le.quiz-positive-feedback21'),
	i18n.t('web-le.quiz-positive-feedback22'),
	i18n.t('web-le.quiz-positive-feedback23'),
	i18n.t('web-le.quiz-positive-feedback24'),
	i18n.t('web-le.quiz-positive-feedback25'),
	i18n.t('web-le.quiz-positive-feedback26'),
	i18n.t('web-le.quiz-positive-feedback27'),
	i18n.t('web-le.quiz-positive-feedback28'),
	i18n.t('web-le.quiz-positive-feedback29'),
	i18n.t('web-le.quiz-positive-feedback30'),
];

export const getTypeNameMap = () => ({
	[MaterialType.CodeCoach]: i18n.t('web-le.material-type-practice'),
	[MaterialType.CodeRepo]: i18n.t('web-le.material-type-code-repo'),
	[MaterialType.CodeProject]: i18n.t('web-le.material-type-code-project'),
	[MaterialType.Lesson]: i18n.t('web-le.material-type-lesson'),
	[MaterialType.Booster]: i18n.t('web-le.material-type-booster'),
	[MaterialType.ModuleQuiz]: i18n.t('web-le.material-type-quiz'),
});

export const getLockedCardModalMessages = (): Record<number, ILockedCardModalData> => ({
	[MaterialType.CodeCoach]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.CodeCoach],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
	[MaterialType.CodeRepo]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.CodeRepo],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
	[MaterialType.CodeProject]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.CodeProject],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
	[MaterialType.Lesson]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.Lesson],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
	[MaterialType.Booster]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.Booster],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
	[MaterialType.ModuleQuiz]: {
		headline: i18n.t('web-le.locked-material-modal-headline', {
			material: getTypeNameMap()[MaterialType.ModuleQuiz],
		}),
		description: i18n.t('web-le.locked-material-modal-description'),
	},
});

export const getQuizFeedbacks = () => Object.freeze({
	[QuizFeedbackType.Correct]: {
		type: QuizFeedbackType.Correct,
		getTitle() {
			return getCorrectAnswerResultText(getQuizFeedbackTexts());
		},
	},
	[QuizFeedbackType.Incorrect]: {
		type: QuizFeedbackType.Incorrect,
		getTitle() {
			return i18n.t('web-le.quiz-incorrect');
		},
	},
	[QuizFeedbackType.ByPassedIncorrect]: {
		type: QuizFeedbackType.Incorrect,
		getTitle() {
			return i18n.t('web-le.quiz-bypassed-incorrect');
		},
	},
	[QuizFeedbackType.FewOptions]: {
		type: QuizFeedbackType.FewOptions,
		getTitle() {
			return i18n.t('web-le.quiz-incorrect-few-options');
		},
	},
	[QuizFeedbackType.Correct3InARow]: {
		type: QuizFeedbackType.Correct,
		getTitle() {
			return i18n.t('web-le.quiz-3-in-a-row');
		},
		animation: {
			data: fire3Animation,
			style: {
				width: '200px',
			},
		},
	},
	[QuizFeedbackType.Correct5InARow]: {
		type: QuizFeedbackType.Correct,
		getTitle() {
			return i18n.t('web-le.quiz-5-in-a-row');
		},
		animation: {
			data: fire5Animation,
			style: {
				width: '280px',
			},
		},
	},
});

export const getFlashMessages = () => ({
	not_found: i18n.t('web-le.complete-previous-message'),
	failed: i18n.t('web-le.something-went-wrong'),
});

export const SlModalIds = {
	freeHeartModal: 'freeHeartModal',
};

export const getNotFoundPages = () => ({
	COURSE_NOT_FOUND: {
		title: i18n.t('web-le.course-not-found-title-wrong'),
		description: i18n.t('web-le.course-not-found-description-wrong'),
		button: {
			title: i18n.t('web-le.course-not-found-button-wrong'),
		},
	},
	LESSON_NOT_FOUND: {
		title: i18n.t('web-le.lesson-not-found-title-wrong'),
		description: i18n.t('web-le.lesson-not-found-description-wrong'),
		button: {
			title: i18n.t('web-le.lesson-not-found-button-wrong'),
		},
	},
});

export const SUPPORTED_SCREENS: ScreenName[] = [
	ScreenName.LessonCelebration,
	ScreenName.Paywall,
	ScreenName.StreakCelebration,
	ScreenName.StreakGoal,
	ScreenName.LeaderboardCelebration,
];

export const LEADERBOARD_USERS_COUNT = 5;

export const getLessonQuitPromptPopup = () => ({
	title: i18n.t('web-le.lesson-quit-prompt-title'),
	positiveButtonTitle: i18n.t('web-le.lesson-quit-prompt-positive-button'),
	negativeButtonTitle: i18n.t('web-le.lesson-quit-prompt-negative-button'),
});

export const getLoseProgressPromptPopup = () => ({
	title: i18n.t('web-le.lose-progress-prompt-title'),
	description: i18n.t('web-le.lose-progress-prompt-description'),
	positiveButtonTitle: i18n.t('web-le.lose-progress-prompt-positive-button'),
	negativeButtonTitle: i18n.t('web-le.lose-progress-prompt-negative-button'),
});

export const getExpiredSessionMessage = () => i18n.t('web-le.expired-session-message');

export const NonRemovableLessonSessionRoutes = [
	'/tiy/',
	'/code-coach/',
	'/code-project/',
	'/code-repo/',
	'/payments/',
];

export const labelsExperimentKey = 'lesson-page-labels';

export const isKodieIntroClosed = 'isKodieIntroClosed';

export const translationWarningShownStatusLSKey = 'translationWarningShownStatus';

export const shouldShowTranslationWarningLSKey = 'shouldShowTranslationWarningLSKey';

export const heartRefillLocationData = {
	moduleOrderNumber: 1,
	lessonOrderNumber: 1,
};

export const COURSE_CATEGORY_TITLE_KEYS: Record<CourseCategory, string> = {
	[CourseCategory.All]: 'all',
	[CourseCategory.Websites]: 'websites',
	[CourseCategory.MobileApps]: 'mobile-apps',
	[CourseCategory.GamesMobileWeb]: 'games-for-mobile-and-web',
	[CourseCategory.BackendSystems]: 'backend-systems',
	[CourseCategory.DataScience]: 'data-science',
	[CourseCategory.AI]: 'ai',
};
