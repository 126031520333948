import { Location } from 'react-router-dom';

import {
	IHearts,
	IUser,
	HeartModalSource,
	ILeaderboardUser,
	ILevelZone,
	BitLocationType,
	IUserSetting,
	LearningExperienceType,
	ILearningExperience,
	IOwnershipStatus,
} from '../../symphony';

import { IFlashMessageTypes } from '../../shared/public/SlFlashMessage/SlFlashMessage';
import {
	IMaterial, IMaterialInfo, ILearningPathSubtree, MaterialType, IMaterialSolutionSubmission, IMaterialStatus, IMaterialChange, LocationType, LessonSessionQuitType, IMaterialHeader,
} from '../../api/private/learn-engine-api/learn-engine-api.interface';

export interface IAction {
	type: ActionType;
	requestType?: RequestType;
	data?: IActionData;
}

export enum ActionType {
	// Network communication
	GetCourseSubtree,
	GetLearningPathSubtree,
	GetCertificate,
	SetLessonData,
	SolveMaterial,
	EnrollCourse,
	GetLandingPageData,
	RefillFreeHearts,
	GetHeartConfigs,
	GetLessonCompleteScreens,
	GetLeaderboardPredictedState,
	GetExperiments,
	GetCourseCategories,
	GetUserSettings,
	UpdateUserSettings,
	SetStreakGoal,
	GetProgrammingLanguages,
	PrefetchLabelsExperimentData,
	
	// Business actions
	RemoveLessonSessionDataRecording,
	StartLessonSessionDataRecording,
	InitializeApp,
	CleanupCourse,
	CleanupPath,
	NavigateToCourse,
	GetCourse,
	GetLearningPath,
	StartOnboarding,
	OpenLesson,
	OpenCodeProject,
	OpenCodeRepo,
	OpenCodeCoach,
	InitializeLessonPage,
	SubmitSolutions,
	ToNextPage,
	CheckAnswer,
	TryAgain,
	ToPreviousPage,
	CloseLesson,
	DeleteSessionDataFromStorage,
	DeleteSessionFromBackend,
	CompleteLesson,
	ByPassLesson,
	ScrollIntoCurrentMaterialElement,
	TryAchieveStreak,
	TrySolveMaterial,
	ClearLessonData,
	UpdateStatuses,
	NavigateToUserLogin,
	NavigateToPro,
	OpenUnlockQuizAnswerModal,
	SetHeartsData,
	OpenHeartModal,
	SetCourseCurrentItems,
	SetPathCurrentItems,
	SetLPComments,
	UpdateDataAfterLogin,
	NextLCSIteration,
	defineLessonQuitPromptInfo,
	SetBitLocation,
	DecreaseBitsCount,
	SolveMaterialCallback,
	MakeMaterialChanges,

	// Mount events
	OnCoursesMount,
	OnCourseContainerMount,
	OnLearningPathContainerMount,
	OnCurrentModuleChildrenMount,
	OnLessonContainerMount,
	OnLessonPageMount,
	OnTheoryMount,
	OnTIYWidgetMount,
	OnTIYMaterialMount,
	OnCRMaterialMount,
	OnCCMaterialMount,
	OnRouteWithCourseContainerMount,
	OnRouteWithLearningPathContainerMount,
	OnLandingPageMount,
	OnCourseMount,
	OnLessonMount,
	OnStreakCelebrationMount,
	OnLandingPageIsMounted,
	OnSlLearnEngineMount,
	OnFreeHeartScreenMount,
	OnLeaderboardScreenMount,
	OnUnlockQuizAnswerMount,
	OnFirstLessonCompleteCelebrationMount,
	OnStreakGoalScreenMount,
	OnLessonCompletePaywallInit,

	// Data change events
	OnLessonPageIndexChange,

	// Unmount events
	OnSlLearnEngineUnmount,
	OnCourseUnmount,
	OnLessonContainerUnmount,
	OnLessonPageUnmount,
	OnLeaderboardScreenUnmount,
	OnLessonQuitPromptUnmount,

	// Click events
	OnCourseClick,
	OnLearningPathClick,
	OnModuleClick,
	OnLessonClick,
	OnModuleQuizClick,
	OnCloseLessonClick,
	onCRPracticeClick,
	OnRunTIYClick,
	OnRunExternalResourceClick,
	onCCPracticeClick,
	onCPPracticeClick,
	OnStartLearningClick,
	OnLPCommentsClick,
	OnCloseLPCommentsClick,
	OnHeartIconClick,
	OnLessonNotFoundClick,
	OnCourseNotFoundClick,
	OnCertificateCTAClick,
	OnCertificateCourseComplete,
	OnLessonCompletedModalContinueClick,
	OnFirstLessonCelebrationContinueClick,
	OnStreakCelebrationScreenContinueClick,
	OnCodeSnippetClick,
	OnImageClick,
	OnCloseNotAllowedFeatureModalClick,
	OnLandingPageCTAClick,
	OnFreeHeartRefillCtaClick,
	OnLeaderboardScreenContinueClick,
	OngoToLeaderboardClick,
	OnLessonQuitPromptContinueClick,
	OnLessonQuitPromptLeaveClick,
	OnLockedCardClick,
	OnLockedCardContinueClick,
	OnUnlockQuizAnswerButtonClick,
	OnUnlockQuizAnswerCloseClick,
	OnStreakGoalScreenButtonClick,
	OnRunExternalResourceCloseClick,
	OnKodieIntroClose,
	OnKodieInNavigationAnimationEnd,
	OnOnboardingCtaClick,
	OnTranslateButtonClick,
	OnSeeOriginalLanguageButtonClickHandler,
	OnSeeTranslatedLanguageButtonClickHandler,
	OnCloseTranslationModalClick,
	OnOpenTranslationModalClick,
	OnTranslationWarningCtaClick,
	OnUpdateSelectedLocale,

	// Callback events
	OnUpdateLPCommentsCB,
	OnLandingSignup,
	OpenModal,
	CloseModal,
	ExpiredSessionIdError,
	OnOnboardingCourseReady,
	OnOnboardingLessonReady,
	OnOnboardingLoopIteration,
	OnOnboardingComplete,

	// Tracking
	Track,
	TrackFirstRefillImpression,
	TrackFirstRefillClick,
	TrackStreakCelebrationImpression,
}

export enum RequestType {
	Parallel,
	Cancelable,
	Sequential,
}

export enum QuizFeedbackType {
	Correct = 'correct',
	Incorrect = 'incorrect',
	FewOptions = 'few_options',
	ByPassedIncorrect = 'byPassedIncorrect',
	Correct3InARow = 'correct3InARow',
	Correct5InARow = 'correct5InARow',
}

export interface IActionData {
	id?: number;
	material?: IMaterial;
	materialInfo?: IMaterialInfo;
	path?: ILearningPathSubtree;
	typeId?: MaterialType;
	learningExperience?: ILearningExperience;
	type?: LearningExperienceType;
	children?: IMaterial[] | JSX.Element[];
	lessonPageIndex?: number;
	isSkipAhead?: boolean;
	alias?: string;
	solutions?: IMaterialSolutionSubmission[];
	status?: IMaterialStatus;
	statusChanges?: IMaterialStatus[];
	materialChanges?: IMaterialChange[];
	lpCommentsCount?: number;
	hearts?: IHearts;
	userData?: IUser;
	source?: string;
	heartModalSource?: HeartModalSource;
	callback?: () => void;
	materialId?: number;
	trackData?: ITrackActionData;
	returnUrl?: string;
	imgUrl?: string;
	comments?: ILpComments;
	isNewRegistered?: boolean;
	modalId?: ModalKeys;
	bitLocationType?: BitLocationType;
	bitLocationDetails?: string;
	price?: number;
	enable?: boolean;
	userSetting?: IUserSetting;
	numberValue?: number;
	location?: Location;
	locationUrl?: LocationType;
	externalResourceUrl?: string;
	materialRelationId?: number;
	lessonSessionQuitType?: LessonSessionQuitType;
	locale?: string;
	isTranslating?: boolean;
	isTranslated?: boolean;
	isFromCourseContainer?: boolean;
	isCourseAvailable?: boolean;
	item?: string;
	materialHeader?: IMaterialHeader;
}

export interface IMaterialProps {
	materialInfo: IMaterialInfo;
	children?: IMaterial[] | JSX.Element[];
	hideHeader?: boolean;
	hideCertificate?: boolean;
	isExpanded?: boolean;
	className?: string;
	onTIYRunClick?: () => void;
}

export interface IWidgetData {
	order?: number;
	title: string;
	description?: string;
	xp?: number;
	status: IMaterialStatus;
	courseOwnership?: IOwnershipStatus;
	type: MaterialType;
	commentsCount?: number;
	isCurrent?: boolean;
	button?: {
		title: string;
		onClick: () => void;
		paid?: boolean;
	};
	iconUrl?: string;
}

export interface IFooterButton {
	title: string;
	disabled: boolean;
	action: ActionType;
	type?: ActionButtonTypes;
	answerState?: QuizFeedbackType;
}

export interface IQuizFeedback {
	type: QuizFeedbackType;
	getTitle: () => string;
	animation?: {
		data: object;
		style?: React.CSSProperties;
	};
}

export interface ICourseCurrentItems {
	alias: string;
	moduleId: number;
	materialId: number;
	isDefault: boolean;
}

export interface ILoadingState {
	unlock?: boolean;
	fullScreen?: boolean;
	footerPrimaryButton?: boolean;
	courseSkeleton?: boolean;
	getCourseCategories?: boolean;
	getProgrammingLanguages?: boolean;
	solveMaterial?: boolean;
	courseTranslation?: boolean;
	prefetchAiWarningExperiment?: boolean;
	isAIChatSettingsLoading?: boolean;
}

export enum OnboardingState {
	Completed = 0,
	InProgress,
	Ready,
	Done,
}

export enum HeartOpenFrom {
	Nav = 'nav',
	Lesson = 'lesson',
	Course = 'course',
}

export enum HeartFullness {
	Full = 'full',
	Enough = 'enough',
	Out = 'out',
}

export interface IDisabledActions {
	question?: boolean;
}

export interface IRouteData {
	courseAlias?: string;
	pathAlias?: string;
	materialId?: number;
	lessonPageIndex?: number;
	locationUrl?: LocationType;
	currentPage?: CurrentPageType;
}

export interface ILpComments {
	count?: number;
	disabled?: boolean;
	hide?: boolean;
}

export enum TrackEventName {
	MaterialClick = 'material_click',
	MaterialImpression = 'material_impression',
	MaterialQuit = 'material_quit',
	MaterialCTAClick = 'material_cta_click',
	MaterialSolutionClick = 'material_solution_click',
	MaterialSolutionImpression = 'material_solution_impression',
	CommentClick = 'comment_click',
	CertificateCTAClick = 'course_certificate_click',
	CodeSnippetClick = 'code_snippet_click',
	ImageClick = 'image_click',
	LearningExpLandingImpression = 'learning_exp_landing_impression',
	LearningExpLandingCTAClick = 'learning_exp_landing_cta_click',
	CelebrationClick = 'celebration_click',
	CelebrationImpression = 'celebration_impression',
	LeaderboardAfterLessonCompletionClick = 'leaderboard_after_lesson_completion_click',
	LeaderboardAfterLessonCompletionImpression = 'leaderboard_after_lesson_completion_impression',
	LessonQuitPopupImpression = 'lesson_quit_popup_impression',
	LessonQuitPopupClick = 'lesson_quit_popup_click',
	UserStreakGoalPopupImpression = 'user_streak_goal_popup_impression',
}

export enum TrackActionName {
	OnMaterialMount = 'onMaterialMount',
	OnMaterialClick = 'onMaterialClick',
	MaterialQuit = 'materialQuit',
	MaterialCTAClick = 'materialCTAClick',
	MaterialSolutionClick = 'materialSolutionClick',
	MaterialSolutionImpression = 'materialSolutionImpression',
	CommentClick = 'commentClick',
	CertificateCTAClick = 'certificateCTAClick',
	CodeSnippetClick = 'codeSnippetClick',
	ImageClick = 'imageClick',
	OnLandingPageMount = 'onLandingPageMount',
	OnLandingPageCTAClick = 'onLandingPageCTAClick',
	OnCompleteFirstLessonCelebrationMount = 'onCompleteFirstLessonCelebrationMount',
	OnFreeHeartScreenMount = 'onFreeHeartScreenMount',
	OnFreeHeartScreenCtaClick = 'onFreeHeartScreenCtaClick',
	OnLeaderboardScreenContinueClick = 'onLeaderboardScreenContinueClick',
	OnLeaderboardScreenGoToLeaderboardClick = 'onLeaderboardScreenGoToLeaderboardClick',
	OnLeaderboardScreenImpression = 'onLeaderboardScreenImpression',
	LessonQuitPromptImpression = 'lessonQuitPromptImpression',
	LessonQuitPromptClick = 'lessonQuitPromptClick',
	OnCompleteFirstLessonCelebrationClick = 'onCompleteFirstLessonCelebrationClick',
	OnStreakGoalScreenMount = 'onStreakGoalScreenMount',
	OnCoursesMount = 'onCoursesMount',
	OnTranslateButtonClick = 'onTranslateButtonClick',
	OnShowOriginalButtonClick = 'onShowOriginalButtonClick',
	OnSeeTranslationButtonClick = 'onSeeTranslationButtonClick',
	OnTranslateCourseButtonClick = 'onTranslateCourseButtonClick',
	OnDynamicTranslationTipCTAClick = 'onDynamicTranslationTipCTAClick',
	OnAICoursePaywall1Mount = 'onAICoursePaywall1Mount',
	OnAICoursePaywall2Mount = 'onAICoursePaywall2Mount',
	OnAICoursePaywall1CtaClick = 'onAICoursePaywall1CtaClick',
	OnAICoursePaywall2Close = 'onAICoursePaywall2Close',
	OnAICoursePaywall2CtaClick = 'onAICoursePaywall2CtaClick',
	OnLessonCompletePaywallInit = 'onLessonCompletePaywallInit'
}

export interface ITrackActionData {
	actionName: TrackActionName;
	relationId?: number;
	materialInfo?: IMaterialInfo;
	solution?: IMaterialSolutionSubmission;
	quitAction?: MaterialQuitAction;
	materialCTAClickType?: MaterialCTAClickType;
	imgUrl?: string;
	alias?: string;
	quitLessonClickType?: LessonQuitPromptClickTypes;
	locale?: string;
	isTranslated?: boolean;
}

export interface IFlashMessage {
	type: IFlashMessageTypes;
	message: string;
}

export enum ModalKeys {
	FreeHeartRefill = 'FreeHeartRefill',
	LessonQuitPrompt = 'LessonQuitPrompt',
	LockedCard = 'LockedCard',
}

export type IModals = {
	[key in ModalKeys]?: boolean;
};

export enum MaterialQuitAction {
	XButton = '1',
	ModalLeave = '2',
	DontCommit = '3',
	Commit = '4',
	Save = '5',
	DoNotSave = '6',
	BackButton = '7',
}

export enum MaterialCTAClickType {
	Continue = '1',
	TryAgain = '2',
	BackButton = '3',
}

export enum CurrentPageType {
	CoursesPage = 'coursesPage',
	CoursePage = 'coursePage',
	PathPage = 'pathPage',
	LessonPage = 'lessonPage',
	LessonCompletedPage = 'lessonCompletedPage',
	CodeCoach = 'codeCoach',
	CodeRepo = 'codeRepo',
	TIY = 'tiy',
}

export interface ILeaderboardStates {
	currentState: (ILeaderboardUserWithPos | ILevelZone)[];
	predictedState: (ILeaderboardUserWithPos | ILevelZone)[];
}

export interface ILeaderboardUserWithPos extends ILeaderboardUser {
	position: number;
}

export interface ILessonQuitPromptPopup {
	title: string;
	positiveButtonTitle: string;
	negativeButtonTitle: string;
	description?: string;
}

export enum LessonQuitPopupType {
	LOSE_PROGRESS = 'lose progress',
	LEAVE_LESSON = 'leave lesson',
}

export interface ILessonQuitPromptInfo {
	shouldShow?: boolean;
	type?: LessonQuitPopupType;
	popupData: ILessonQuitPromptPopup;
}

export interface ILockedCardModalData {
	headline: string;
	description: string;
	button?: string;
}

export enum LeaderboardClickTypes {
	Continue = 'continue',
	GoToLeaderboard = 'go_to_the_leaderboard',
}

export enum LessonQuitPromptClickTypes {
	Quit = 'QUIT',
	Stay = 'STAY',
}

export enum UserSettingKey {
	IntroToHeartTooltipShown = 'intro_to_heart_tooltip_shown',
	IntroBitsAndXpsShown = 'intro_bits_xps_shown',
}

export enum ActionButtonTypes {
	primary = 'primary',
	secondary = 'secondary',
	snowflake = 'snowflake',
	tertiary = 'tertiary',
}

export interface IHeartExpirmentPayload {
	key: string;
	payload: {
		moduleOrderNumber: number;
		maxRefillLessonOrderNumber: number;
		lessonOrderNumbers: number[];
	};
	experimentKey: string;
}
