import React from 'react';

import {
	LogoLinkedin,
	LogoFacebook,
	LogoTwitter,
	LogoInstagram,
	LogoYoutube,
} from '@sololearnorg/sol';

import { i18n } from '../../../symphony';
import { ISlFooterLinksProps } from '../../private/components/SlFooterLinks/SlFooterLinks';
import { ISlFooterSocialProps } from '../../private/components/SlFooterSocial/SlFooterSocial';

export const getSocialLinks = (): ISlFooterSocialProps => ({
	socialLinks: [
		{
			id: 0,
			link: 'https://www.linkedin.com/company/sololearn-inc-/',
			icon: <LogoLinkedin />,
			text: i18n.t('web-footer.socials-linkedin'),
			color: '#0077b5',
			trackElement: 'linkedin',
		},
		{
			id: 1,
			link: 'https://www.facebook.com/SoloLearn',
			icon: <LogoFacebook />,
			text: i18n.t('web-footer.socials-fb'),
			color: '#4267b2',
			trackElement: 'facebook',
		},
		{
			id: 2,
			link: 'https://twitter.com/SoloLearn',
			icon: <LogoTwitter />,
			text: i18n.t('web-footer.socials-twitter'),
			color: '#1da1f2',
			trackElement: 'twitter',
		},
		{
			id: 3,
			link: 'https://www.instagram.com/sololearn/',
			icon: <LogoInstagram />,
			text: i18n.t('web-footer.socials-insta'),
			trackElement: 'instagram',
		},
		{
			id: 4,
			link: 'https://www.youtube.com/channel/UCXiOyrjY_jqNCFoQTotILTw',
			icon: <LogoYoutube />,
			text: i18n.t('web-footer.socials-youtube'),
			color: 'red',
			trackElement: 'youtube',
		},
	],
});

export const getFooterLinks = (): ISlFooterLinksProps => ({
	linksConfig: [
		{
			name: i18n.t('web-footer.links-home'),
			link: '/',
			navigationType: 'internal',
			trackElement: 'home',
		},
		{
			name: i18n.t('web-footer.links-pricing'),
			link: '/pricing',
			navigationType: 'external',
			trackElement: 'pricing'
		},
		{
			name: i18n.t('web-footer.links-faq'),
			link: '/faq',
			navigationType: 'internal',
			trackElement: 'faq',
		},
		{
			name: i18n.t('web-footer.links-contact'),
			link: '/contact',
			navigationType: 'internal',
			trackElement: 'contact',
		},
		{
			name: i18n.t('web-footer.links-bug-bounty-program'),
			link: '/bug-bounty',
			navigationType: 'internal',
			trackElement: 'bugbounty',
		},
		{
			name: i18n.t('web-footer.links-careers'),
			link: '/career',
			navigationType: 'internal',
			trackElement: 'career',
		},
		{
			name: i18n.t('web-footer.links-terms'),
			link: '/terms-of-use',
			navigationType: 'internal',
			trackElement: 'terms',
		},
		{
			name: i18n.t('web-footer.links-privacy-policy'),
			link: '/privacy-policy',
			navigationType: 'external',
			trackElement: 'privacypolicy',
		},
		{
			name: i18n.t('web-footer.links-mobile'),
			link: '/mobile',
			navigationType: 'external',
		},
	],
});
