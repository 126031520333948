import React, { FC, useEffect, useState } from 'react';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';
import { PaywallsStore } from '../../paywalls.store';
import { PaywallPage, PaywallPageTypes } from '../../../../api/public/subscription-api';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallPageDrawer.scss';
import { GenericPaywallPage } from '../GenericPaywallPage/GenericPaywallPage';
import { WebToAppPaywallPage } from '../WebToAppPaywallPage/WebToAppPaywallPage';
import { LongPaywallPage } from '../LongPaywallPage/LongPaywallPage';
import { Container } from '../../../../symphony';
import { CheckoutService } from '../../services/checkout.service';
import { WTAPaywallV2 } from '../WTAPaywallV2/WTAPaywallV2';

export const PaywallPageDrawer: FC = () => {
  useStyles(s);
  const {
    paywallData,
    paywallNavigation
  } = useContainerData('paywalls', PaywallsStore, [
    'paywallData',
    'paywallNavigation'
  ]);
  const [page, setPage] = useState<PaywallPage>(null);
  const {
    disableLongPaywallCheckout,
    enableLongPaywallCheckout,
    disableWTAPaywallV2Checkout,
    enableWTAPaywallV2Checkout
  } = Container.take('paywalls', CheckoutService);

  useEffect(() => {
    setPage(paywallData.data.find(p => p.order.toString() === paywallNavigation.step));
  }, [paywallNavigation]);

  const pageComponent = (type: PaywallPageTypes) => {
    switch (type) {
      case PaywallPageTypes.generic:
        disableLongPaywallCheckout();
        disableWTAPaywallV2Checkout();
        return <div className='transition-item'>
          <GenericPaywallPage page={page} />
        </div>;
      case PaywallPageTypes.webToApp:
        disableLongPaywallCheckout();
        disableWTAPaywallV2Checkout();
        return <div className='transition-item'>
          <WebToAppPaywallPage page={page} />
        </div>;
      case PaywallPageTypes.wtaV2:
        disableLongPaywallCheckout();
        enableWTAPaywallV2Checkout();
        return <div className='transition-item'>
          <WTAPaywallV2 page={page} />
        </div>;
      case PaywallPageTypes.long:
        disableWTAPaywallV2Checkout();
        enableLongPaywallCheckout();
        return <LongPaywallPage page={page} />;
    }
  };

  return <div className="drawer">
    <div className="drawer-wrapper">
      {page && <TransitionGroup component={null}>
        <CSSTransition
          key={page?.order}
          timeout={500}
          classNames="fade"
        >
          {pageComponent(page.type)}
        </CSSTransition>
      </TransitionGroup>}
    </div>
  </div >;
};