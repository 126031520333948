import React, { FC, RefObject, useEffect, useState } from "react";
import s from "./WTAPaywallV2Discount.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useInView } from "../../../paywalls.utils";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { Container } from "../../../../../symphony";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../../paywalls.store";
import { PaywallsService } from "../../../services/paywalls.service";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallClickTypes } from "../../../paywalls.constants";

type Props = {
  data: {
    discount: number;
  };
  sectionOrder: number;
};

export const WTAPaywallV2Discount: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { countdown } = useContainerData('paywalls', PaywallsStore, ['countdown']);
  const { countdownSetup } = Container.take('paywalls', PaywallsService);
  const [numbers, setNumbers] = useState<number[]>([]);
  const { trackSectionView, trackClick } = Container.take('paywalls', PaywallsTrackingService);
  const { scrollToPlans } = Container.take('paywalls', CheckoutService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    const subscription = countdownSetup(600).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const hours = Math.floor(countdown / 3600);
    const minutes = Math.floor((countdown % 3600) / 60);
    const secs = countdown % 60;
    const pad = (num: number) => num.toString().padStart(2, '0');
    const padString = `${pad(hours)}${pad(minutes)}${pad(secs)}`;
    setNumbers(padString.split('').map(char => +char));
  }, [countdown]);

  useEffect(() => {
    isInView && trackSectionView('discount', sectionOrder);
  }, [isInView]);

  const handleClick = () => {
    trackClick(PaywallClickTypes.cta, 'discount');
    scrollToPlans();
  };

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-discount">
    <div className="wtapv2-discount-data">
      <span>Discount <span className="wtapv2-discount-data-discount">{data.discount}%</span> reserved for:</span>
      <div className="wtapv2-discount-data-timer">
        <span>{numbers[2]}</span>
        <span>{numbers[3]}</span>
        <span>:</span>
        <span>{numbers[4]}</span>
        <span>{numbers[5]}</span>
      </div>
    </div>
    <WTAPaywallV2Button
      onClick={handleClick}
      styles={{
        height: '42px',
        fontSize: '12px',
        width: 'auto',
        padding: '0 16px'
      }}
      text={"Get My Plan"}
    />
  </div>;
};
