import React, { FC, useEffect, useState } from "react";
import s from "./LongPaywallSuccess.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { i18n } from "../../../../../../symphony";
import { SlIcon } from "@sololearnorg/icons";
import { useContainerData } from "../../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../../checkout.store";
import { LongPaywallProduct, LongPaywallProductNames } from "../../../../paywalls.constants";
import { PaywallPageButton } from "../../../PaywallPageButton/PaywallPageButton";
import { PaywallButtonAction, PaywallButtonType } from "../../../../../../api/public/subscription-api";

const items = [
	{
		title: 'web-checkout.congrats-item0-title',
		subtitle: 'web-checkout.congrats-item0-subtitle',
		icon: 'infiniteHearts'
	},
	{
		title: 'web-checkout.congrats-item1-title',
		subtitle: 'web-checkout.congrats-item1-subtitle',
		icon: 'noAds'
	},
	{
		title: 'web-checkout.congrats-item2-title',
		subtitle: 'web-checkout.congrats-item2-subtitle',
		icon: 'plusGradient'
	},
	{
		title: 'web-checkout.congrats-item3-title',
		subtitle: 'web-checkout.congrats-item3-subtitle',
		icon: 'practiceWithAI'
	},
];

type Props = {};

export const LongPaywallSuccess: FC<Props> = () => {
	useStyles(s);
	const [product, setProduct] = useState<LongPaywallProduct>();
	const {
		availableProducts,
		selectedProductKey
	} = useContainerData('paywalls', CheckoutStore, [
		'availableProducts',
		'selectedProductKey'
	]);

	useEffect(() => {
		if (selectedProductKey && availableProducts) {
			setProduct(availableProducts.find(p => p.product.key === selectedProductKey));
		}
	}, [selectedProductKey, availableProducts]);

	return product ? <div className="checkout-success">
		<div className="checkout-success-modal">
			<div className="checkout-success-container">
				<div className="checkout-success-title-container">
					<div className="checkout-success-icon">
						<SlIcon iconId={'sparkleSmallStars'} />
					</div>
					<h4 className="checkout-success-title">
						{i18n.t('web-checkout.congrats-title')}
					</h4>
					<div className="checkout-success-icon">
						<SlIcon iconId={'sparkleSmallStars'} />
					</div>
				</div>
				<p className='checkout-success-subtitle'>{
					product.meta.name === LongPaywallProductNames.maxAnnualNoTrial || product.meta.name === LongPaywallProductNames.maxAnnualTrial
						? i18n.t('web-paywall.long.checkout.success.max.description')
						: i18n.t('web-paywall.long.checkout.success.pro.description')
				}</p>
				<div className="checkout-success-values">
					{items.map(i => (
						<div key={i.title} className="checkout-success-value">
							<div className="checkout-success-value-icon">
								<SlIcon iconId={i.icon} />
							</div>
							<div>
								<p className='checkout-success-value-title'>{i18n.t(i.title)}</p>
								<p className='checkout-success-value-subtitle'>{i18n.t(i.subtitle)}</p>
							</div>
						</div>
					))}
				</div>
				<PaywallPageButton
					type={PaywallButtonType.primary}
					action={PaywallButtonAction.finalize}
					text='web-checkout.congrats-continue-button'
					order={23}
				/>
			</div>
		</div>
	</div> : null;
};