import React, { FC, RefObject, useEffect, useRef } from "react";
import s from "./WTAPaywallV2Plans.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { WTAPaywallV2ProductCard } from "../components/WTAPaywallV2ProductCard";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { WTAPaywallV2Label } from "../components/WTAPaywallV2Label";
import { WTAPaywallIcons } from "../components/WTAPaywallV2Icons";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { PaywallClickTypes, WTAPaywallV2Product, WTAPaywallV2ProductMeta } from "../../../paywalls.constants";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {
    title: string;
    products: WTAPaywallV2ProductMeta[];
    selectedProductKey: string;
  };
  sectionOrder: number;
};

export const WTAPaywallV2Plans: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { w2aV2Products, selectedProductKey, scrollToPlans } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey', 'scrollToPlans'
  ]);
  const { setSelectedProductKey, scrollToPaymentInfo } = Container.take('paywalls', CheckoutService);
  const { trackClick, trackSectionView } = Container.take('paywalls', PaywallsTrackingService);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    if (scrollToPlans && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToPlans]);

  useEffect(() => {
    isInView && trackSectionView('plans', sectionOrder);
  }, [isInView]);

  const handlePlanClick = (product: WTAPaywallV2Product) => {
    let clickType: PaywallClickTypes;
    switch (product.product.period) {
      case 1:
        clickType = PaywallClickTypes.monthlyBlock;
        break;
      case 3:
        clickType = PaywallClickTypes.quarterlyBlock;
        break;
      case 12:
        clickType = PaywallClickTypes.annualBlock;
        break;
    }
    trackClick(clickType);
    setSelectedProductKey(product.product.key);
  };

  const handleCTAClick = () => {
    trackClick(PaywallClickTypes.cta, 'plan-change');
    scrollToPaymentInfo();
  };

  const getProductPrice = () => {
    const product = w2aV2Products?.find(p => p.product.key === selectedProductKey);
    return product.meta.priceType === 'full'
      ? product.product.discountedAmount.toFixed(2)
      : (product.product.discountedAmount / (product.product.period * 30)).toFixed(2) + '/day';
  };

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-plans">
    <WTAPaywallV2Typography
      level="h1"
      text={data.title}
    />
    <div className="wtapv2-plans-tabs">
      {w2aV2Products?.map(product => (
        <div
          key={product.product.key}
          className={`wtapv2-plans-tab ${selectedProductKey === product.product.key ? 'selected' : ''}`}
          onClick={() => handlePlanClick(product)}
        >
          <WTAPaywallV2Typography level="h2" text={product.meta.name} />
        </div>
      ))}
    </div>
    <WTAPaywallV2ProductCard
      size="large"
      product={w2aV2Products?.find(p => p.product.key === selectedProductKey)}
    />
    {selectedProductKey && <WTAPaywallV2Button
      onClick={handleCTAClick}
      className="wtapv2-plans-button"
      text={`Start learning for $${getProductPrice()}`}
    />}
    <WTAPaywallV2Label
      text="30-day money-back guarantee"
      icon={<WTAPaywallIcons.certificate />}
      theme="yellow"
      className="wtapv2-plans-label"
    />
  </div >;
};