import React, { FC, useEffect } from "react";
import { WTAPayawllV2Card } from "../components/WTAPayawllV2Card";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { SlIcon } from "@sololearnorg/icons";
import { Container, i18n } from "../../../../../symphony";
import s from "./WTAPaywallV2Congrats.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { PaywallsService } from "../../../services/paywalls.service";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { PaywallClickTypes } from "../../../paywalls.constants";

export const WTAPaywallV2Congrats: FC = () => {
  useStyles(s);
  const { closePaywall } = Container.take('paywalls', PaywallsService);
  const { trackSectionView, trackClick } = Container.take('paywalls', PaywallsTrackingService);

  useEffect(() => {
    trackSectionView('congrats', 100);
  }, []);

  const handleContinueClick = () => {
    trackClick(PaywallClickTypes.cta, 'congrats');
    closePaywall();
  };

  return (
    <div className="wtapv2-congrats">
      <WTAPayawllV2Card className="wtapv2-congrats-card" styles={{ padding: '42px 24px' }}>
        <div className="wtapv2-congrats-header">
          <div className="wtapv2-congrats-icon">
            <SlIcon iconId={'sparkleSmallStars'} />
          </div>
          <WTAPaywallV2Typography
            text={i18n.t('web-checkout.congrats-title')}
            level={'h1'}
            className="wtapv2-congrats-title"
          />
          <div className="wtapv2-congrats-icon">
            <SlIcon iconId={'sparkleSmallStars'} />
          </div>
        </div>
        <WTAPaywallV2Typography
          text={i18n.t('You have successfully unlocked Sololearn MAX. Time to level up your learning!')}
          level={'paragraph'}
          className="wtapv2-congrats-subtitle"
        />
        <WTAPaywallV2Button text={'Continue'} onClick={handleContinueClick} />
      </WTAPayawllV2Card>
    </div>
  );
}; 