import React, { useEffect, useState } from 'react';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import useStyles from 'isomorphic-style-loader/useStyles';
import {
	AuthService, Container, IUser, SubscriptionPlanService,
	UserTrackService
} from '../../../../symphony';

import { SlNavigationDataService } from '../../services/sl-navigation-data.service';
import { SlNavigationContext } from '../../global-constants';

import s from './SlProButton.scss';
import { SlNavigationService } from '../../services/sl-navigation.service';
import { PlanConfigKey } from '../../../../api/public/subscription-api';
import { useContainerData } from '../../../../shared/public/SlHooks/useContainerData';

export function SlProButton() {
	useStyles(s);
	const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
	const [bitsMenuOpen, setBitsMenuOpen] = useState<boolean>(false);
	const [streakMenuOpen, setStreakMenuOpen] = useState<boolean>(false);
	const [userData, setUserData] = useState<IUser>();
	const [showPro, setShowPro] = useState<boolean>();

	const subscriptionPlanService = Container.take('global', SubscriptionPlanService);
	const { userMenuOpen$, bitsMenuOpen$, streakMenuOpen$ } = Container.take(
		SlNavigationContext,
		SlNavigationDataService,
	);
	const { userTrack } = useContainerData('global', UserTrackService, ['userTrack']);

	const service = Container.take(SlNavigationContext, SlNavigationService);

	useEffect(() => {
		const { userInfo$ } = Container.take('global', AuthService);
		const unsubscriber = new Subject<void>();
		merge(
			userInfo$.pipe(tap(setUserData)),
			userMenuOpen$.pipe(tap(setUserMenuOpen)),
			bitsMenuOpen$.pipe(tap(setBitsMenuOpen)),
			streakMenuOpen$.pipe(tap(setStreakMenuOpen)),
			subscriptionPlanService.subscriptionPlanConfigs.pipe(
				tap(_ => setShowPro(subscriptionPlanService.getConfig(PlanConfigKey.showNavigationGoPro) as boolean))
			)
		).pipe(takeUntil(unsubscriber)).subscribe();

		return () => {
			unsubscriber.next();
			unsubscriber.complete();
		};
	}, []);

	return userTrack && userData && showPro
		? (
			<div
				className={`sl-pro-button ${userMenuOpen || bitsMenuOpen || streakMenuOpen ? 'sl-pro-button--open' : ''}`}
				onClick={() => service.openNavigationPaywall()}
			>
				<div className="sl-pro-button-wrapper">
					<span>Go PRO</span>
				</div>
			</div>
		) : null;
}
