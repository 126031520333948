import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';

import {
	IconCheckThick,
	IconFlash,
	IconGift,
	IconStreakSaver,
} from '@sololearnorg/sol';

import s from './StreakIcon.scss';

export enum StreakIconColor {
	Reached = 'reached',
	NotReached = 'notReached',
	Milestone = 'milestone',
	Today = 'today',
	Future = 'future',
	Longest = 'longest',
	Saver = 'saver',
	Freeze = 'freeze',
}

export enum StreakIconType {
	Default = 1,
	Milestone,
	Today,
	Saver,
}

interface IStreakIconData {
	id: string;
	type?: StreakIconType;
	color: StreakIconColor;
	border?: boolean;
	small?: boolean;
	extraSmall?: boolean;
}

interface IIconData {
	size: number;
	className: string;
}

const STREAK_ICON_COMPONENT_MAP: Record<StreakIconType, React.FC<IIconData>> = {
	[StreakIconType.Default]: IconFlash,
	[StreakIconType.Milestone]: IconGift,
	[StreakIconType.Today]: IconCheckThick,
	[StreakIconType.Saver]: IconStreakSaver,
};

const STREAK_ICON_SIZE = 18;

export const StreakIcon: React.FC<IStreakIconData> = React.memo(
	({
		id,
		type,
		color,
		border = false,
		small = false,
		extraSmall = false,
	}) => {
		useStyles(s);
		const Icon = STREAK_ICON_COMPONENT_MAP[type];
		const size = STREAK_ICON_SIZE;
		return (
			<div
				sl-test-data={`cmpStreakIcon${id}`}
				className={`streak-icon-container ${color}${border ? ' border' : ''
					}${small || extraSmall
						? ` ${small ? 'small' : 'extraSmall'}`
						: ''
					}`}
			>
				{type && <Icon className="streak-icon" size={size} />}
			</div>
		);
	},
);
