import React, { FC, RefObject, useEffect, useRef, useState } from "react";
import s from "./WTAPaywallV2PaymentInfo.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2Label } from "../components/WTAPaywallV2Label";
import { WTAPaywallIcons } from "../components/WTAPaywallV2Icons";
import { WTAPaywallV2Typography } from "../components/WTAPaywallV2Typography";
import { WTAPaywallV2List } from "../components/WTAPaywallV2List";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { WTAPaywallV2Product } from "../../../paywalls.constants";
import { useInView } from "../../../paywalls.utils";
import { Container } from "../../../../../symphony";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";

type Props = {
  data: {
    label: {
      text: string;
      theme: "blue" | "green" | "yellow" | "purple";
      icon: string;
    };
    title: string;
    items: string[];
  };
  sectionOrder: number;
};

export const WTAPaywallV2PaymentInfo: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { label, title, items } = data;

  const { w2aV2Products, selectedProductKey, scrollToPaymentInfo } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey', 'scrollToPaymentInfo'
  ]);

  const [selectedProduct, setSelectedProduct] = useState<WTAPaywallV2Product>(null);
  const [processedItems, setProcessedItems] = useState<string[]>([]);

  const { trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const [ref, isInView] = useInView({
    threshold: 0.7
  });

  useEffect(() => {
    isInView && trackSectionView('payment-info', sectionOrder);
  }, [isInView]);

  useEffect(() => {
    if (items && selectedProduct) {
      const processedItems = items.map(item => {
        return item
          .replace('[[price]]', `$${selectedProduct?.product.discountedAmount}`)
          .replace('[[period]]',
            (() => {
              if (selectedProduct?.product.period === 12) {
                return 'year';
              } else if (selectedProduct?.product.period === 1) {
                return 'month';
              } else {
                return `${selectedProduct?.product.period} months`;
              }
            })()
          );
      });
      setProcessedItems(processedItems);
    }
  }, [items, selectedProduct]);

  useEffect(() => {
    setSelectedProduct(w2aV2Products?.find(p => p.product.key === selectedProductKey));
  }, [selectedProductKey]);

  useEffect(() => {
    if (scrollToPaymentInfo && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToPaymentInfo]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-payment-info">
    <WTAPaywallV2Label
      theme={label.theme}
      icon={React.createElement(WTAPaywallIcons[label.icon])}
      text={label.text}
      className="wtapv2-payment-info-label"
    />
    <WTAPaywallV2Typography
      level="h1"
      text={title}
    />
    <WTAPaywallV2List
      items={processedItems.map(item => <p dangerouslySetInnerHTML={{ __html: item }}></p>)}
      size="large"
      className="wtapv2-payment-info-list"
    />
  </div>;
};