import { TrackingEventNames } from '../../public/tracking/trackingEventNames';

export const UUID_EVENTS = [
	TrackingEventNames.downloadNowBannerImpression,
	TrackingEventNames.downloadNowBannerClick,
	TrackingEventNames.featureUnavailabilityImpression,
	TrackingEventNames.featureUnavailabilityClick,
	TrackingEventNames.userCodeBitsImpression,
	TrackingEventNames.userCodeBitsPostClick,
	TrackingEventNames.userCodeBitsSearchClick,
	TrackingEventNames.userCodeBitsCTAClick,
	TrackingEventNames.userCodeBitsPaginationClick,
	TrackingEventNames.userCodeBitsSectionClick,
	TrackingEventNames.solotutorImpression,
	TrackingEventNames.solotutorSendMessage,
	TrackingEventNames.solotutorCourseLinkClick,
	TrackingEventNames.kodiePlaygroundClick,
	TrackingEventNames.kodiePlaygroundFeedback,
	TrackingEventNames.kodieLessonClick,
	TrackingEventNames.kodieLessonFeedback,
	TrackingEventNames.kodieLessonImpression,
	TrackingEventNames.dynamicTranslationButtonClick,
	TrackingEventNames.dynamicTranslationCTAClick,
	TrackingEventNames.dynamicTranslationTipCTAClick,
	TrackingEventNames.aiPlaygroundScreenClick,
	TrackingEventNames.aiPopupWarning,
	TrackingEventNames.aiResetChat,
	TrackingEventNames.aiSettingsUpdated,
	TrackingEventNames.aiPaywallsImpression,
	TrackingEventNames.aiPaywallsClick,
	TrackingEventNames.paywallImpression,
	TrackingEventNames.paywallClick,
	TrackingEventNames.paywallSectionView,
	TrackingEventNames.manageSubscriptionClick,
	TrackingEventNames.cancellationFlowClick,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
];

export const AF_WEB_USER_ID_EVENTS = [
	TrackingEventNames.signinCompleted,
	TrackingEventNames.signupCompleted,
	TrackingEventNames.landingPageTargetedImpression,
	TrackingEventNames.landingPageTargetedClick,
	TrackingEventNames.onboardingClick,
	TrackingEventNames.onboardingImpression,
	TrackingEventNames.mobilePromotionLandingPageClick,
	TrackingEventNames.onHomeStartLearningCTAClick,
	TrackingEventNames.homeHeaderSectionView,
	TrackingEventNames.subscriptionGroupSettingsImpression,
	TrackingEventNames.subscriptionGroupSettingsActionClick,
	TrackingEventNames.userStreakGoalPopupImpression,
	TrackingEventNames.subscriptionGroupBannerClick,
	TrackingEventNames.subscriptionGroupBannerImpression,
	TrackingEventNames.materialGroupComplete,
	TrackingEventNames.compilerLanguagesCatalogClick,
	TrackingEventNames.compilerLanguagesCatalogImpression,
	TrackingEventNames.signinPageImpression,
	TrackingEventNames.signupPageImpression,
	TrackingEventNames.onCourseClick,
	TrackingEventNames.courseCatalogImpression,
	TrackingEventNames.onDiscussJumpLinkClick,
	TrackingEventNames.downloadNowBannerImpression,
	TrackingEventNames.downloadNowBannerClick,
	TrackingEventNames.featureUnavailabilityImpression,
	TrackingEventNames.featureUnavailabilityClick,
	TrackingEventNames.joinWaitlist,
	TrackingEventNames.landingPageImpression,
	TrackingEventNames.landingPageClick,
	TrackingEventNames.paywallImpression,
	TrackingEventNames.paywallClick,
	TrackingEventNames.paywallSectionView,
	TrackingEventNames.manageSubscriptionClick,
	TrackingEventNames.cancellationFlowClick,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
];

export const GU_ID_EVENTS = [
	TrackingEventNames.homeHeaderSectionView,
	TrackingEventNames.signupCompleted,
	TrackingEventNames.solotutorImpression,
	TrackingEventNames.solotutorSendMessage,
	TrackingEventNames.solotutorCourseLinkClick,
	TrackingEventNames.kodiePlaygroundClick,
	TrackingEventNames.kodiePlaygroundFeedback,
	TrackingEventNames.kodieLessonClick,
	TrackingEventNames.kodieLessonFeedback,
	TrackingEventNames.kodieLessonImpression,
	TrackingEventNames.dynamicTranslationButtonClick,
	TrackingEventNames.dynamicTranslationCTAClick,
	TrackingEventNames.dynamicTranslationTipCTAClick,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
];

export const COUNTRY_CODE_EVENTS = [
	TrackingEventNames.landingPageImpression,
	TrackingEventNames.landingPageClick,
	TrackingEventNames.joinWaitlist,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
];

export const DEVICE_TYPE_EVENTS = [
	TrackingEventNames.landingPageImpression,
	TrackingEventNames.landingPageClick,
	TrackingEventNames.joinWaitlist
];

export const LOCALE_NAME_EVENTS = [
	TrackingEventNames.aiPaywallsImpression,
	TrackingEventNames.aiPaywallsClick,
	TrackingEventNames.paywallImpression,
	TrackingEventNames.paywallClick,
	TrackingEventNames.paywallSectionView,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
];

export const USER_AGENT_EVENTS = [
	TrackingEventNames.paywallImpression,
	TrackingEventNames.paywallClick,
	TrackingEventNames.paywallSectionView,
	TrackingEventNames.webToAppOpen,
	TrackingEventNames.webToAppStart,
	TrackingEventNames.webToAppPageImpression,
	TrackingEventNames.webToAppPageComplete,
	TrackingEventNames.webToAppQuizAnswer,
	TrackingEventNames.webToAppClick,
	TrackingEventNames.webToAppCheckpointCompleted,
	TrackingEventNames.webToAppEmailProvide
]
