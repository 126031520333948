import React, { FC, RefObject, useEffect } from "react";
import s from "./WTAPaywallV2SelectedPlan.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPaywallV2ProductCard } from "../components/WTAPaywallV2ProductCard";
import { WTAPaywallV2Button } from "../components/WTAPaywallV2Button";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { PaywallsTrackingService } from "../../../services/paywalls.tracking.service";
import { PaywallClickTypes } from "../../../paywalls.constants";
import { useInView } from "../../../paywalls.utils";

type Props = {
  data: {};
  sectionOrder: number;
};

export const WTAPaywallV2SelectedPlan: FC<Props> = ({ data, sectionOrder }) => {
  useStyles(s);
  const { w2aV2Products, selectedProductKey } = useContainerData('paywalls', CheckoutStore, [
    'w2aV2Products', 'selectedProductKey'
  ]);
  const [ref, isInView] = useInView({
    threshold: 0.7
  });
  const { scrollToPaymentInfo, scrollToPlans } = Container.take('paywalls', CheckoutService);
  const { trackClick, trackSectionView } = Container.take('paywalls', PaywallsTrackingService);

  const handleCTAClick = () => {
    trackClick(PaywallClickTypes.cta, 'selected-plan');
    scrollToPaymentInfo();
  };

  const handleSeeAllPlansClick = () => {
    trackClick(PaywallClickTypes.productChange, 'see-all-plans');
    scrollToPlans();
  };

  const getProductPrice = () => {
    const product = w2aV2Products?.find(p => p.product.key === selectedProductKey);
    return product.meta.priceType === 'full'
      ? product.product.discountedAmount.toFixed(2)
      : (product.product.discountedAmount / (product.product.period * 30)).toFixed(2) + '/day';
  };

  useEffect(() => {
    isInView && trackSectionView('selected-plan', sectionOrder);
  }, [isInView]);

  return <div ref={ref as RefObject<HTMLDivElement>} className="wtapv2-selected-plan">
    <WTAPaywallV2ProductCard
      size="small"
      product={w2aV2Products?.find(p => p.product.key === selectedProductKey)}
    />
    {selectedProductKey && <WTAPaywallV2Button
      onClick={handleCTAClick}
      text={`Start learning for $${getProductPrice()}`}
      className="wtapv2-selected-plan-button"
    />}
    <a className="wtapv2-selected-plan-link" onClick={handleSeeAllPlansClick}>See all plans</a>
  </div>;
};