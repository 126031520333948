import React, { FC, useEffect } from "react";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './PaywallContainer.scss';
import { useContainerData } from "../../../../shared/public/SlHooks/useContainerData";
import { PaywallsStore } from "../../paywalls.store";
import { PaywallErroredData, PaywallNavigationPage, PaywallOfferModalId, PaywallState } from "../../paywalls.constants";
import { Container } from "../../../../symphony";
import { PaywallsService } from "../../services/paywalls.service";
import {
	useIcons,
	sololearnMono,
	stars,
	appleAward,
	starPro,
	infiniteHearts,
	noAds,
	practiceWithAI,
	prioritySupport,
	paywallTimeline1,
	paywallTimeline2,
	paywallTimeline3,
	correctRound,
	offerHeader,
	plusGradient,
	statsGradient,
	magicHand,
	crossPlatform,
	sparkleSmallStars,
	thunderFilled,
	checkmarkFilled,
	boostEfficiency,
	unleashCreativity,
	understandData,
	stayCompetitive,
	check,
	simpleStar,
} from "@sololearnorg/icons";
import { CheckoutWrapper } from "../checkout/CheckoutWrapper/CheckoutWrapper";
import { SlModal } from "../../../../shared/public/SlModal/SlModal";
import { PaywallOffer } from "../PaywallOffer/PaywallOffer";
import { PaywallHeader } from "../ui/PaywallHeader/PaywallHeader";
import { Congrats } from "../Congrats/Congrats";
import { PaywallPageDrawer } from "../PaywallPageDrawer/PaywallPageDrawer";
import { PaywallPageTypes } from "../../../../api/public/subscription-api";
import { Button } from '@sololearnorg/sol';

export const PaywallContainer: FC = () => {
	useStyles(s);
	useIcons([
		sololearnMono,
		starPro,
		stars,
		appleAward,
		infiniteHearts,
		noAds,
		practiceWithAI,
		prioritySupport,
		paywallTimeline1,
		paywallTimeline2,
		paywallTimeline3,
		correctRound,
		offerHeader,
		plusGradient,
		statsGradient,
		magicHand,
		crossPlatform,
		sparkleSmallStars,
		thunderFilled,
		checkmarkFilled,
		boostEfficiency,
		unleashCreativity,
		understandData,
		stayCompetitive,
		check,
		simpleStar
	]);
	const {
		paywallState,
		paywallData,
		paywallNavigation,
		paywallStyles
	} = useContainerData('paywalls', PaywallsStore, [
		'paywallState',
		'paywallData',
		'paywallNavigation',
		'paywallStyles'
	]);
	const { initPaywallInfo, closePaywall } = Container.take('paywalls', PaywallsService);

	useEffect(() => {
		initPaywallInfo();
	}, []);

	const loader = <div className="paywalls-loader">
		<div style={{ width: '100%', height: 'calc(100vh - 48px)' }} />
	</div>;

	const error = <div className="paywalls-error">
		<span>
			Oops! Something went wrong on our site. Please try again later. Thanks for your patience!
		</span>
		<Button onClick={() => closePaywall(false)}>
			Continue learning
		</Button>
	</div>;

	const container = <div className="paywalls-container" style={paywallStyles?.container || {}}>
		<div className="paywalls-inner" style={paywallStyles?.inner || {}}>
			{paywallData && !(paywallData instanceof PaywallErroredData) && paywallData.data[0].type !== PaywallPageTypes.long && <div className="paywalls-header-container" style={paywallStyles?.header || {}}>
				<PaywallHeader version={1} />
			</div>}
			<div className="paywalls-body" style={paywallStyles?.body || {}}>
				{!paywallData && loader}
				{paywallData && (paywallData instanceof PaywallErroredData) && error}
				{paywallData && !(paywallData instanceof PaywallErroredData) && <>
					{paywallNavigation?.page === PaywallNavigationPage.paywall && <PaywallPageDrawer />}
					{paywallNavigation?.page === PaywallNavigationPage.checkout && <CheckoutWrapper />}
					{paywallNavigation?.page === PaywallNavigationPage.congrats && <Congrats />}
				</>}
			</div>
		</div>
		<SlModal
			id={PaywallOfferModalId}
			cssClass="paywalls-offer-modal"
			keepOnOverlay={true}
		><PaywallOffer /></SlModal>
	</div >;

	return paywallState === PaywallState.open ? container : null;
};