import React, { FC, useEffect, useState } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { WTAPayawllV2Card } from "./WTAPayawllV2Card";
import { WTAPaywallV2Label } from "./WTAPaywallV2Label";
import { WTAPaywallIcons } from "./WTAPaywallV2Icons";
import { WTAPaywallV2Typography } from "./WTAPaywallV2Typography";
import { WTAPaywallV2Product } from "../../../paywalls.constants";

type Props = {
  size: 'small' | 'large';
  product: WTAPaywallV2Product;
  className?: string;
};

export const WTAPaywallV2ProductCard: FC<Props> = ({ className, size, product }) => {
  useStyles(s);
  const [priceType, setPriceType] = useState<'full' | 'perDay'>('full');
  const [mainPrevious, setMainPrevious] = useState<string | null>(null);
  const [mainCurrent, setMainCurrent] = useState<string | null>(null);
  const [secondaryPrevious, setSecondaryPrevious] = useState<string | null>(null);
  const [secondaryCurrent, setSecondaryCurrent] = useState<string | null>(null);

  useEffect(() => {
    if (product) {
      setPriceType(product.meta.priceType || 'full');
      if (product.meta.originalPrice) {
        setMainPrevious(product.meta.priceType === 'full'
          ? product.meta.originalPrice.toFixed(2)
          : (product.meta.originalPrice / (product.product.period * 30)).toFixed(2));
        setSecondaryPrevious(product.meta.priceType === "full"
          ? (product.meta.originalPrice / (product.product.period * 30)).toFixed(2)
          : product.meta.originalPrice.toFixed(2)
        );
      } else {
        setMainPrevious(null);
        setSecondaryPrevious(null);
      }
      setMainCurrent(
        product.meta.priceType === 'full'
          ? product.product.discountedAmount.toFixed(2)
          : (product.product.discountedAmount / (product.product.period * 30)).toFixed(2)
      );
      setSecondaryCurrent(
        product.meta.priceType === 'full'
          ? (product.product.discountedAmount / (product.product.period * 30)).toFixed(2)
          : product.product.discountedAmount.toFixed(2)
      );
    }
  }, [product]);

  return <WTAPayawllV2Card
    className={`wtapv2-product-card ${className || ''} ${size}`}
    styles={{ padding: size === 'large' ? '24px 20px' : '24px 20px 15px' }}
  >
    {product && product.meta.badge && <WTAPaywallV2Label
      className={`wtapv2-product-card-label ${size}`}
      text={product.meta.badge}
      icon={null}
      theme="purple"
    />}

    {size === 'large' && product && <>
      {product.meta.label && <WTAPaywallV2Label
        text={product.meta.label.text}
        icon={product.meta.label.icon ? React.createElement(WTAPaywallIcons[product.meta.label.icon]) : null}
        theme={product.meta.label.theme}
      />}
      <div className="wtapv2-product-card-primary-prices">
        {mainPrevious && <WTAPaywallV2Typography
          text={`$${mainPrevious}`}
          level="h1"
          className="wtapv2-product-card-primary-prices-previous"
        />}
        <WTAPaywallV2Typography
          text={`$${mainCurrent}`}
          level="h1"
          className="wtapv2-product-card-primary-prices-current"
        />
        {priceType === 'perDay' && <WTAPaywallV2Typography
          text="per day"
          level="h3"
          className="wtapv2-product-card-primary-prices-label"
        />}
      </div>
      <div className="wtapv2-product-card-secondary-prices">
        <WTAPaywallV2Typography
          text={`$${secondaryCurrent}`}
          level="caption"
          className="wtapv2-product-card-secondary-prices-current"
        />
        <WTAPaywallV2Typography
          text={priceType === 'full' ? 'per day' : 'total'}
          level="caption"
          className="wtapv2-product-card-secondary-prices-label"
        />
      </div>

      <WTAPaywallV2Typography
        text={product.meta.billingCycleInfo}
        level="paragraph"
      />
    </>}
    {size === 'small' && product && <>
      <div className="wtapv2-product-card-icon">
        <WTAPaywallIcons.dollarSign />
      </div>
      <div className="wtapv2-product-card-content">
        <WTAPaywallV2Typography
          level="caption"
          text="Price"
        />
        <div className="wtapv2-product-card-secondary-prices">
          {mainPrevious && <WTAPaywallV2Typography
            level="h2"
            text={`$${mainPrevious}`}
            className="wtapv2-product-card-secondary-prices-previous small"
          />}
          <WTAPaywallV2Typography
            level="h2"
            text={`$${mainCurrent}`}
            className="wtapv2-product-card-secondary-prices-current small"
          />
          {priceType === 'perDay' && <WTAPaywallV2Typography
            text="per day"
            level="caption"
            className="wtapv2-product-card-secondary-prices-label small"
          />}
        </div>
        <WTAPaywallV2Typography
          text={product.meta.billingCycleInfo}
          level="paragraph"
        />
      </div>
    </>}
  </WTAPayawllV2Card>;
};
