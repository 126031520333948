import React, { FC } from "react";
import s from "./WTAPaywallW2Components.scss";
import useStyles from "isomorphic-style-loader/useStyles";

type Props = {
  selected: boolean;
  className?: string;
};

export const WTAPaywallV2RadioButton: FC<Props> = ({
  selected, className
}) => {
  useStyles(s);
  return <div className={`wtapv2-radio-button ${selected ? 'selected' : ''} ${className ? className : ''}`}>
    <div className={`wtapv2-radio-button-inner ${selected ? 'selected' : ''}`}></div>
  </div>;
};
