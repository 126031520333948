import React, { FC, useEffect } from "react";
import { Container } from "../../../../../symphony";
import { CheckoutService } from "../../../services/checkout.service";
import { useContainerData } from "../../../../../shared/public/SlHooks/useContainerData";
import { CheckoutStore } from "../../../checkout.store";
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './ApplePay.scss';

export const ApplePay: FC = () => {
  useStyles(s);
  const { applePayInitialized, supportsApplePay, transactionInProcess } = useContainerData('paywalls', CheckoutStore, [
    'applePayInitialized', 'supportsApplePay', 'transactionInProcess'
  ]);
  const { initApplePay, makeApplePayPayment } = Container.take('paywalls', CheckoutService);

  useEffect(() => {
    initApplePay();
  }, []);

  return (supportsApplePay
    ? (applePayInitialized
      ? <div onClick={makeApplePayPayment} className={`apple-pay-button apple-pay-button-black ${transactionInProcess ? 'disabled' : ''}`}></div>
      : <div className="apple-pay-placeholder"></div>)
    : null);
};